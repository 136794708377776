const validateField = (name, value, confirmPassword = null) => {
  switch (name) {
    case "name":
      if (!value) {
        return "Name is required";
      } else if (value.trim().length < 3) {
        return "Name must be at least 3 characters";
      } else if (value.trim().length > 50) {
        return "Name must be at most 50 characters";
      }
      break;
    case "programPlace":
    case "noProgram":
    case "whatProgram":
    case "city":
    case "state":
    case "country":
      if (!value) {
        return "This field is required";
      }
      break;
    case "phoneNumber":
      if (!value) {
        return "Mobile number is required";
      } else if (value.trim().length !== 10) {
        return "Mobile number must be exactly 10 characters";
      } else if (!/^[0-9]+$/.test(value)) {
        return "Invalid mobile number";
      }
      break;
    case "dateFrom":
    case "toDate":
      if (!value) {
        return "Date is required";
      }
      break;
    case "note":
      if (!value) {
        return "Note is required";
      }
      break;
    case "link":
      if (!value) {
        return "Link is required";
      }
      break;
    case "subject":
      if (!value) {
        return "Subject is required";
      }
      break;
    case "text":
      if (!value) {
        return "Message is required";
      }
      break;
    case "message":
      if (!value) {
        return "Message is required";
      }
      break;
    case "question":
      if (!value) {
        return "Question is required";
      }
      break;
    case "answer":
      if (!value) {
        return "Answer is required";
      }
      break;
    case "buyLink":
      if (!value) {
        return "Link is required";
      }
      // else if (
      //   !/^https?:\/\/([A-Za-z0-9\-]+\.)+[A-Za-z]{2,}(\/.*)*$/.test(value) &&
      //   !/^www\./i.test(value)
      // ) {
      //   return "Invalid link format. Links must begin with 'http://' or 'https://' or 'www.'";
      // }
      break;
    case "title":
      if (!value) {
        return "Title is required";
      }
      break;
    case "description":
      if (!value) {
        return "Description is required";
      }
      break;
    case "playlist":
    case "playlistName":
      if (!value) {
        return "Playlist Name is required";
      }
      break;
    case "startDate":
      if (!value) {
        return "Start Date is required";
      }
      break;
    case "endDate":
      if (!value) {
        return "End Date is required";
      }
      break;
    case "location":
      if (!value) {
        return "Location is required";
      }
      break;
    case "age":
      if (!value) {
        return "Age is required";
      } else if (value.length < 1 || value.length > 2) {
        return "The age must be a number between 1 and 100";
      } else if (!/^[0-9]+$/.test(value)) {
        return "Invalid age";
      } else if (value == 0) {
        return "Invalid age";
      }
      break;
    case "firstName":
    case "name":
      if (!value) {
        return "Name is required";
      } else if (value.trim().length < 3) {
        return "Name must be at least 3 characters";
      } else if (value.trim().length > 50) {
        return "Name must be at most 50 characters";
      }
      break;
    case "lastName":
      if (!value) {
        return "Last name is required";
      } else if (value.trim().length < 3) {
        return "Last name must be at least 3 characters";
      } else if (value.trim().length > 50) {
        return "Last name must be at most 50 characters";
      }
      break;
    case "email":
      if (!value) {
        return "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        return "Invalid email address";
      } else if (value.trim().length < 5) {
        return "Email must be at least 3 characters";
      } else if (value.trim().length > 255) {
        return "Email must be at most 255 characters";
      }
      break;
    case "password":
      if (!value) {
        return "Password is required";
      } else if (value.trim().length < 8 || value.trim().length > 20) {
        return "Password must be between 8 and 20 characters";
      } else if (!/(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*])/.test(value)) {
        return "Password must contain at least one letter, one number, and one special character (!@#$%^&*)";
      }
      break;
    case "confirmPassword":
      if (!value) {
        return "Confirm password is required";
      } else if (value !== confirmPassword) {
        return "Passwords do not match";
      }
      break;

    case "userType":
      if (!value) {
        return "User type is required";
      } else if (typeof value !== "number") {
        return "User type must be a number";
      }
      break;
    case "mobile":
      if (!value) {
        return "Mobile number is required";
      } else if (value.trim().length !== 10) {
        return "Mobile number must be exactly 10 characters";
      } else if (!/^[0-9]+$/.test(value)) {
        return "Invalid mobile number";
      }
      break;
    case "whatsappNumber":
      if (!value) {
        return "Whatsapp number is required";
      } else if (!/^\+?[1-9]\d{1,14}$/.test(value)) {
        return "Invalid Whatsapp number";
      }
      break;
    case "acceptTermCondition":
      if (!value) {
        return "You must accept the terms and conditions";
      }
      break;
    case "photo":
    case "file":
    case "image":
      if (!value) {
        return "Photo is required";
      } else if (typeof value === "string" && value.startsWith("http")) {
        // The value is an image URL ending with .jpg, .jpeg, or .png
        return;
      } else if (!value.type || !value.type.match(/^image\//)) {
        return "Invalid file type. Only image files are allowed";
      } else if (value.size > 5 * 1024 * 1024) {
        return "File size exceeds the limit of 5MB";
      }
      break;
    case "price":
      if (isNaN(value) || Number(value) === 0) {
        return "Price must be a number and greater than 0";
      } else if (value.length <= 1) {
        return "Price must be at least 2 digits long";
      } else if (value.length >= 5) {
        return "Price must be at most 4 digits long";
      }
      break;
    case "state":
    case "city":
    case "education":
    case "occupation":
    case "baitStatus":
    case "baitDuration":
    case "categoryName":
      if (!value) {
        return "This Field is required";
      }
      break;
    case "audio":
      if (!value) {
        return "Audio is required";
      }
      break;
    default:
      break;
  }
  return "";
};

export default validateField;
export const itakaafValidateField = (name, value, extraValues = {}) => {
  switch (name) {
    case "name":
      if (!value) {
        return "Name is required";
      }
      break;
    case "fatherName":
      if (!value) {
        return "Father Name is required";
      }
      break;
    case "pincode":
      if (!value) {
        return "Pincode is required";
      }
      break;
    case "address":
      if (!value) {
        return "Address is required";
      }
      break;
    case "itikaafPurpose":
    case "islamicQalification":
    case "itikaafTimes":
      if (!value) {
        return "This Field is required";
      }
      break;
    case "age":
      if (!value) {
        return "Age is required";
      }
      break;
    case "city":
      if (!value) {
        return "City is required";
      }
      break;
    case "state":
      if (!value) {
        return "State is required";
      }
      break;
    case "nafilFrom":
      if (!value) {
        return "Nafil Start Date is required";
      }
      if(extraValues.startDate && extraValues.endDate){
        const startDate = new Date(extraValues.startDate);
        const endDate = new Date(extraValues.endDate);
        const nafilFromDate = new Date(value);
        if(nafilFromDate < startDate || nafilFromDate > endDate){
          return `Nafil Start Date should be between ${startDate?.toISOString().split('T')[0]} and ${endDate?.toISOString().split('T')[0] }`;
        }
      }
      break;
    case "nafilTo":
      if (!value) {
        return "Nafil End Date is required";
      }
      if(extraValues.startDate && extraValues.endDate && extraValues.nafilFromDate){
        const startDate = new Date(extraValues.startDate);
        const endDate = new Date(extraValues.endDate);
        const nafilFromDate = new Date(extraValues.nafilFromDate);
        const nafilToDate = new Date(value);
        const diffInDays = Math.ceil((nafilToDate - nafilFromDate) / (1000 * 60 * 60 * 24));
        if(nafilToDate < startDate || nafilToDate > endDate){
          return `Nafil End Date should be between ${startDate?.toISOString().split('T')[0]} and ${endDate?.toISOString().split('T')[0]}`;
        }
        if(nafilToDate <= nafilFromDate){
          return "Nafil End Date should be after Nafil Start Date";
        }
        if(diffInDays < 1 || diffInDays > 8){
          return "There should be a gap of 1 to 9 days between Nafil Start Date and Nafil End Date";
        }
      }
      break;
    case "country":
      if (!value) {
        return "Country is required";
      }
      break;
    case "whatsappNumber":
      if (!value) {
        return "Whatsapp Number is required";
      }
      break;
    case "contactNumber":
      if (!value) {
        return "Contact Number is required";
      }
      break;
    case "email":
      if (!value) {
        return "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        return "Invalid email address";
      } else if (value.trim().length < 5) {
        return "Email must be at least 3 characters";
      } else if (value.trim().length > 255) {
        return "Email must be at most 255 characters";
      }
      break;
    case "nafilDays":
      if (!value) {
        return "Nafil Days is required";
      } else if (value <= 0) {
        return "Nafil Days should be greater than 0";
      } else if (value > 10) {
        return "Nafil Days should be less than or equal to 10";
      }
      break;
    case "itakaafType":
      if (!value) {
        return "Itakaaf Type is required";
      }
      break;
    case "education":
      if (!value) {
        return "Education is required";
      }
      break;
    case "occupation":
      if (!value) {
        return "Occupation is required";
      }
      break;
    case "kisseBaitHain":
      if (!value) {
        return "This Field is required";
      }
      break;
    case "kabseBaitHain":
      if (!value) {
        return "This Field is required";
      }
      break;
    case "etekaafMaqsad":
      if (!value) {
        return "This Field is required";
      }
      break;
    case "masjidId":
      if (!value) {
        return "Select masjid";
      }
      break;
    case "notes":
      if (!value) {
        return "Notes is required";
      }
      break;
    default:
      break;
  }
  return "";
};

export const createFloorValidateField = (name, value) => {
  switch (name) {
    case "masjidName":
    case "cityName":
    case "rowCount":
    case "columnCount":
    case "regStartDateTime":
    case "regEndDateTime":
    case "confirmationDateTime":
    case "ithkaafDateTime":
      if (!value) {
        return "This Field is required";
      }
      break;
    default:
      break;
  }
  return "";
};
