import React, { useEffect, useState } from "react";
import {
  bookSelectedSeat,
  getCityBasedUserList,
  getCityBasedUserListWithoutPagination,
  getFloorPlanListByIdApi,
  swapSelectedSeat,
} from "../../../api/adminApi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import DashboardLayout from "../dashboardLayout";
import { adminSelector } from "../../../redux/slicers/adminSlice";
import "../floor-plan/floor-plan.css";
import { ReactComponent as SeatIcon } from "../../../assets/images/floor/mosqueIcon.svg";
import { errorToast } from "../../../helpers/projectHelper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Seat = ({
  rowId,
  floorId,
  seat,
  rowName,
  onSelectSeat,
  selectedSeat,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { pathname } = useLocation();
  const masjidId = pathname.split("/")[3];

  const isSeatSelected = selectedSeat?.some((s) => s.seatId === seat?._id);

  const seatStyle = {
    backgroundColor: isSeatSelected
      ? "blue" // Seat is selected, show blue background
      : seat?.user
      ? "green" // Seat is registered, show red background
      : isHovered
      ? "white" // Change the background color on hover
      : "white", // Default color is white
  };

  const handleSeatHover = () => {
    const updatedSelectedSeats = Array.isArray(selectedSeat)
      ? [...selectedSeat]
      : [];
    if (seat?._id) {
      const seatIndex = updatedSelectedSeats.findIndex(
        (s) => s.seatId === seat._id
      );

      if (!isSeatSelected && updatedSelectedSeats.length < 2) {
        // Seat is not selected, the selected seats count is less than the user count, and there is at least one user available
        updatedSelectedSeats.push({
          seatId: seat._id,
          masjidId: masjidId,
          floorId: floorId,
          rowId: rowId,
          userName: seat?.user?.name,
        });
      } else if (isSeatSelected) {
        // Seat is already selected, unbook it
        updatedSelectedSeats.splice(seatIndex, 1);
      }
      onSelectSeat(updatedSelectedSeats);
    }
  };

  return (
    <div
      className="seat-icon"
      onClick={() => {
        // if (seat?.user) {
        setIsHovered(true);
        handleSeatHover();
        // }
      }}
    >
      {seat && (
        <Tooltip
          className="seat-icon"
          title={`${seat?.user?.name ?? "Not Assigned yet"} - ${seat?._id}`}
          placement="top"
        >
          <div
            className={`seat-icon-info ${
              isSeatSelected ? "selected" : seat?.user ? "active" : ""
            }`}
          >
            <SeatIcon />
            <div className="row-name">
              {rowName}
              {seat?.number}
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

const Row = ({ rowId, floorId, row, onSelectSeat, selectedSeat }) => {
  return (
    <div className="seat-view-wrapper">
      {row.seats.map((seat) => (
        <Seat
          rowId={rowId}
          floorId={floorId}
          key={seat._id}
          seat={seat}
          rowName={row?.rowName}
          onSelectSeat={onSelectSeat}
          selectedSeat={selectedSeat}
        />
      ))}
    </div>
  );
};

const Floor = ({ floor, onSelectSeat, selectedSeat }) => {
  return (
    <div className="floor-wrapper">
      <h2>
        {floor.floorName}
        {/* Floor: <span>{floor.floorName}</span> */}
      </h2>
      {/* <p>
        Capacity: <span>{floor.capacity}</span>
      </p> */}
      <div className="row-wrapper">
        {floor.rows.map((row) => (
          <Row
            rowId={row._id}
            floorId={floor._id}
            key={row._id}
            row={row}
            onSelectSeat={onSelectSeat}
            selectedSeat={selectedSeat}
          />
        ))}
      </div>
    </div>
  );
};

const MasjidDetails = ({
  masjid,
  selectedSeat,
  setSelectedSeat,
  handleSelectedSeat,
  loading = false,
  navigate,
}) => {
  const handleSelectSeat = (seatId) => {
    setSelectedSeat(seatId);
  };

  return (
    <div className="view-floor-wrapper itikaaf-wrap">
      <div className="back-btn-wrap">
        <Button
          variant="contained"
          className="back-btn"
          onClick={() => navigate(-1)}
        >
          <i class="fa-solid fa-arrow-left"></i> Back
        </Button>
      </div>
      <h1>Masjid Details</h1>
      <h2>
        <span>{masjid?.name}</span>
        <span className="city">{masjid?.city ? `(${masjid?.city})` : ""}</span>
      </h2>
      {/* <p>
        City: <span>{masjid?.city}</span>
      </p> */}
      {masjid?.floors?.map((floor) => (
        <Floor
          key={floor._id}
          floor={floor}
          onSelectSeat={handleSelectSeat}
          selectedSeat={selectedSeat}
        />
      ))}
      {/* {selectedSeat && <p>Selected Seat: {selectedSeat}</p>} */}
      <button
        className="btn btn-green-gradient text-white"
        onClick={handleSelectedSeat}
      >
        {loading ? (
          <PulseLoader
            color="#fff"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          />
        ) : (
          "Swap Selected Seat"
        )}
      </button>
    </div>
  );
};

const ItikaafAssignedFloor = ({ checkedItems }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { floorPlanListsById = [] } = useSelector(adminSelector);
  const { pathname } = useLocation();
  const cityName = pathname.split("/")[4];
  const masjidId = pathname.split("/")[3];
  // const { pathname } = location
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getFloorPlanListById();
  }, []);
  const getFloorPlanListById = () => {
    dispatch(getFloorPlanListByIdApi(masjidId, setLoading));
  };

  const handleSelectedSeat = () => {
    if (selectedSeat?.length == 2) {
      if (selectedSeat[0]?.userName || selectedSeat[1]?.userName) {
        const formattedResponse = {
          seatId1: selectedSeat[0]?.seatId,
          seatId2: selectedSeat[1]?.seatId,
          isAdmin: true,
        };
        setLoadingBtn(true);
        dispatch(
          swapSelectedSeat(
            formattedResponse,
            setLoadingBtn,
            setOpen,
            getFloorPlanListById,
            setSelectedSeat
          )
        );
      } else {
        errorToast("Please select atleast one assigned seat");
      }
    } else {
      errorToast("Please select the seat");
    }
  };

  // const getFloorPlanList = () => {
  //   dispatch(
  //     getCityBasedUserListWithoutPagination(cityName, masjidId, setLoading,setUserList)
  //   );
  // };

  // useEffect(() => {
  //   getFloorPlanList();
  // }, []);

  const handleOtpSubmit = () => {
    if (selectedSeat?.length == 2) {
      const formattedResponse = {
        seatId1: selectedSeat[0]?.seatId,
        seatId2: selectedSeat[1]?.seatId,
        otp1: Number(otp1),
        otp2: Number(otp2),
      };
      setLoading(true);
      dispatch(swapSelectedSeat(formattedResponse, setLoading));
    } else {
      errorToast("Please select the seat");
    }
    handleClose();
  };


  return (
    <>
      {loading ? (
        <div className="loading-table-wrapper">
          <PulseLoader
            color="#0e5472"
            style={{ display: "flex", justifyContent: "center" }}
          />
        </div>
      ) : (
        <DashboardLayout>
          <MasjidDetails
            masjid={floorPlanListsById}
            setSelectedSeat={setSelectedSeat}
            selectedSeat={selectedSeat}
            loading={loadingBtn}
            handleSelectedSeat={handleSelectedSeat}
            navigate={navigate}
          />
          <React.Fragment>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle sx={{ fontWeight: "600", paddingBottom: "0" }}>
                {"Please submit the OTP and to SWAP"}
              </DialogTitle>
              <DialogContent style={{ paddingTop: "20px" }}>
                <div style={{ display: "flex", gap: "20px" }}>
                  <TextField
                    label={`Enter OTP for ${
                      (selectedSeat && selectedSeat[0]?.userName) ?? "OTP"
                    }`}
                    name="otp1"
                    value={otp1}
                    onChange={(e) => setOtp1(e.target.value)}
                  />
                  <TextField
                    label={`Enter OTP for ${
                      (selectedSeat && selectedSeat[1]?.userName) ?? "OTP"
                    }`}
                    name="otp2"
                    value={otp2}
                    onChange={(e) => setOtp2(e.target.value)}
                  />
                </div>
              </DialogContent>
              <DialogActions sx={{ gap: "20px" }}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={handleOtpSubmit}>
                  Submit OTP
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </DashboardLayout>
      )}
    </>
  );
};

export default ItikaafAssignedFloor;
