import React from "react";
import ReactLoading from "react-loading";

const ReactLoader = () => {
  return (
    <div className="react-loading-div">
      <ReactLoading
        className="react-loading"
        type={"spinningBubbles"}
        color={"#0e5472"}
        height={"15%"}
        width={"15%"}
      />
    </div>
  );
};

export default ReactLoader;
