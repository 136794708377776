import userRoutes from "./userRoutes";
import adminRoutes from "./adminRoutes";

const ROLES_ROUTES = {
  user: userRoutes,
  admin: adminRoutes,
};

//Role names based on routes
const ROLES_NAMES = {
  user: "userRoutes",
  admin: "adminRoutes",
};

export const getRoutes = (role) => {
  return ROLES_ROUTES[role];
};

//   return ROLES_ROUTES[role] ?? unauthorizedRoutes;