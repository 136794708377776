import React , {useState, useEffect, useCallback, useRef, useLayoutEffect} from "react"
import { getLocal, getUserData } from "./projectHelper";


export const useSession = () => {
  return { ...getUserData(), token: getLocal() };
};

//Hook

export const useDebouncedEffect = (effect, delay , deps) => {
  const firstRenderRef = useRef(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false
      return
    }
      const handler = setTimeout(() => {
          callback();
      }, delay);

      return () => {
          clearTimeout(handler);
      };
  }, [callback, delay]);
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}