import React, { useEffect, useState } from "react";
import {
  bookSelectedSeat,
  getCityBasedUserList,
  getCityBasedUserListWithoutPagination,
  getFloorPlanListByIdApi,
} from "../../../api/adminApi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../dashboardLayout";
import { adminSelector } from "../../../redux/slicers/adminSlice";
import "../floor-plan/floor-plan.css";
import { ReactComponent as SeatIcon } from "../../../assets/images/floor/seat.svg";
import { Button } from "@mui/material";
import { errorToast } from "../../../helpers/projectHelper";

const Seat = ({
  rowId,
  floorId,
  seat,
  rowName,
  onSelectSeat,
  selectedSeat,
  userList,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { pathname } = useLocation();
  const masjidId = pathname.split("/")[4];

  const isSeatSelected = selectedSeat?.some((s) => s.seatId === seat?._id);
  const seatStyle = {
    backgroundColor: isSeatSelected
      ? "blue" // Seat is selected, show blue background
      : seat?.user
      ? "green" // Seat is registered, show red background
      : isHovered
      ? "lightgray" // Change the background color on hover
      : "white", // Default color is white
  };

  const handleSeatHover = () => {
    const updatedSelectedSeats = Array.isArray(selectedSeat)
      ? [...selectedSeat]
      : [];

    if (seat?._id) {
      const seatIndex = updatedSelectedSeats.findIndex(
        (s) => s.seatId === seat._id
      );

      if (!isSeatSelected && updatedSelectedSeats.length < userList.length) {
        // Seat is not selected, and the selected seats count is less than the user count
        updatedSelectedSeats.push({
          seatId: seat._id,
          userId: userList[updatedSelectedSeats.length],
          masjidId: masjidId,
          floorId: floorId,
          rowId: rowId,
        });
      } else if (isSeatSelected) {
        // Seat is already selected, unbook it
        updatedSelectedSeats.splice(seatIndex, 1);
      }

      onSelectSeat(updatedSelectedSeats);
    }
  };

  return (
    <div
      className="seat-icon"
      onMouseEnter={() => {
        if (!seat?.user) {
          setIsHovered(true);
          handleSeatHover();
        }
      }}
      onMouseLeave={() => setIsHovered(false)}
      // style={seatStyle}
    >
      <div
        className={`seat-icon-info ${
          isSeatSelected ? "selected" : seat?.user ? "active" : ""
        }`}
      >
        <SeatIcon />
        <div className="row-name">
          {rowName}
          {seat?.number}
        </div>
      </div>
    </div>
  );
};

const Row = ({ rowId, floorId, row, onSelectSeat, selectedSeat, userList }) => {
  return (
    <div className="seat-view-wrapper">
      {row.seats.map((seat) => (
        <Seat
          rowId={rowId}
          floorId={floorId}
          key={seat._id}
          seat={seat}
          rowName={row?.rowName}
          onSelectSeat={onSelectSeat}
          selectedSeat={selectedSeat}
          userList={userList}
        />
      ))}
    </div>
  );
};

const Floor = ({ floor, onSelectSeat, selectedSeat, userList }) => {
  return (
    <div className="floor-wrapper view-floor-content">
      <h2>
        {floor.floorName}
        {/* Floor: <span>{floor.floorName}</span> */}
      </h2>
      {/* <p>
        Capacity: <span>{floor.capacity}</span>
      </p> */}
      {floor.rows.map((row) => (
        <Row
          rowId={row._id}
          floorId={floor._id}
          key={row._id}
          row={row}
          onSelectSeat={onSelectSeat}
          selectedSeat={selectedSeat}
          userList={userList}
        />
      ))}
    </div>
  );
};

const MasjidDetails = ({
  masjid,
  userList,
  selectedSeat,
  setSelectedSeat,
  handleSelectedSeat,
  isPopup = false,
  navigate
}) => {
  const handleSelectSeat = (seatId) => {
    setSelectedSeat(seatId);
  };

  return (
    <div
      className="view-floor-wrapper"
      style={isPopup ? { marginTop: "50px" } : {}}
    >
      <div className="back-btn-wrap">
        <Button
          variant="contained"
          className="back-btn"
          onClick={() => navigate(-1)}
        >
          <i class="fa-solid fa-arrow-left"></i> Back
        </Button>
      </div>
      <h1>Masjid Details</h1>
      <h2>
        <span>{masjid?.name}</span>
        <span className="city">{masjid?.city ? `(${masjid?.city})` : ""}</span>
      </h2>
      {/* <p>
        City: <span>{masjid?.city}</span>
      </p> */}
      {masjid?.floors?.map((floor) => (
        <Floor
          key={floor._id}
          floor={floor}
          onSelectSeat={handleSelectSeat}
          selectedSeat={selectedSeat}
          userList={userList}
        />
      ))}
      {/* {selectedSeat && <p>Selected Seat: {selectedSeat}</p>} */}
      <button
        className="btn btn-green-gradient text-white"
        onClick={handleSelectedSeat}
      >
        {" "}
        Book Selected Seat
      </button>
    </div>
  );
};

const ViewFloorPlan = ({ checkedItems, isPopup = false, handleClose, getFloorPlanList }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { floorPlanListsById = [] } = useSelector(adminSelector);
  const { pathname } = useLocation();
  const cityName = pathname.split("/")[4];
  const masjidId = pathname.split("/")[5];
  // const { pathname } = location
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getFloorPlanListById();
  }, []);
  const getFloorPlanListById = () => {
    dispatch(getFloorPlanListByIdApi(id, setLoading));
  };
  const handleSelectedSeat = () => {
    if (selectedSeat.length > 0) {
      dispatch(
        bookSelectedSeat(selectedSeat, setLoading, getFloorPlanListById, setSelectedSeat, handleClose, getFloorPlanList)
      );
    } else {
      errorToast("Please select the seat");
    }
  };

  // const getFloorPlanList = () => {
  //   dispatch(
  //     getCityBasedUserListWithoutPagination(cityName, masjidId, setLoading,setUserList)
  //   );
  // };

  // useEffect(() => {
  //   getFloorPlanList();
  // }, []);

  return (
    // <DashboardLayout>
    <MasjidDetails
      masjid={floorPlanListsById}
      userList={checkedItems}
      setSelectedSeat={setSelectedSeat}
      selectedSeat={selectedSeat}
      handleSelectedSeat={handleSelectedSeat}
      isPopup={isPopup}
      navigate={navigate}
    />
    // </DashboardLayout>
  );
};

export default ViewFloorPlan;
