import React, { useRef, useState } from "react";
import "./style.css";

const Accordian = ({ title, content, id, shajrah = false }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className="accordion-item">
        <div
          className={`accordion-title ${isActive ? "open" : ""}`}
          onClick={(e) => handleClick(e)}
        >
          {shajrah ? (
            <div>
              <span>{id}. </span>
              {title}
            </div>
          ) : (
            <div>{title}</div>
          )}

          <div>
            <span>{isActive ? "-" : "+"}</span>
          </div>
        </div>
        {isActive && <div className="accordion-content">{content}</div>}
      </div>
    </>
  );
};

export default Accordian;

export const AccordianAdminFaq = ({
  title,
  content,
  item,
  qn,
  handleEditFaq,
  handleDelFaq,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className="accordion-item">
        <div
          className={
            isActive ? "accordion-title open" : "accordion-title close"
          }
          onClick={(e) => handleClick(e)}
        >
          <div>{title}</div>
          <div className="accordion-btn">
            <button
              className="btn edit-btn btn-primary"
              onClick={() => handleEditFaq(item, qn)}
            >
              Edit
            </button>
            <button
              className="btn delete-btn btn-danger"
              onClick={() => handleDelFaq(item, qn)}
            >
              Delete
            </button>
            <span>{isActive ? "-" : "+"}</span>
          </div>
        </div>
        {isActive && <div className="accordion-content">{content}</div>}
      </div>
    </>
  );
};
