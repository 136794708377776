import { lazy } from "react";
import { Outlet } from "react-router-dom";
import {
  VIDEO_UPLOAD,
  FLOOR_PLAN,
  USER_LIST,
  DASHBOARD,
  ADMIN_FAQ,
  ADMIN_EVENTS,
  ADMIN_LIBRARY,
  ADMIN_SALAQEEN,
  ADMIN_FAQ_CATEGORY,
  ADMIN_VIDEO_UPLOAD_CATEGORY,
  ADMIN_CONTACT,
  AUDIO_UPLOAD,
  CREATE_FLOOR_PLAN,
  VIEW_FLOOR_PLAN,
  ITIKAAF_APPROVED_LIST,
  ITIKAAF_CONFIRMED_LIST,
  MASJID_LIST,
  VIEW_USER_FLOOR_PLAN,
  ITIKAAF_ASSIGNED_LIST,
  ITIKAAF_ASSIGNED_SEATS_LIST,
  ITIKAAF_ASSIGNED_SEATS,
} from "../config/constants/routePathConstants";
import CreateFloorPlan from "../pages/adminContainer/floor-plan/CreateFloorPlan";
import ViewFloorPlan from "../pages/adminContainer/floor-plan/ViewFloorPlan";
import ViewUserFloorPlan from "../pages/adminContainer/user-list/ViewUserFloorPlan";
import ItikaafApprovedList from "../pages/adminContainer/user-list/ItikaafApprovedList";
import ItikaafConfirmedList from "../pages/adminContainer/user-list/ItikaafConfirmedList";
import ItikaafAssignedList from "../pages/adminContainer/user-list/ItikaafAssignedList";
import MasjidList from "../pages/adminContainer/user-list/MasjidList";
import ItikaafAssignedFloor from "../pages/adminContainer/itikaaf-assigned-floor/ItikaafAssignedFloor";
import ItikaafAssignedFloorList from "../pages/adminContainer/itikaaf-assigned-floor";

const DashBoard = lazy(() => import("../pages/adminContainer/dashboard"));
const VideoUpload = lazy(() => import("../pages/adminContainer/video-upload"));
const AudioUpload = lazy(() => import("../pages/adminContainer/audio-upload"));
const VideoUploadCategory = lazy(() => import("../pages/adminContainer/video-upload/category"));
const FloorPlan = lazy(() => import("../pages/adminContainer/floor-plan"));
const UserList = lazy(() => import("../pages/adminContainer/user-list"));
const AdminEvents = lazy(() => import("../pages/adminContainer/admin-events"));
const AdminContact = lazy(() => import("../pages/adminContainer/admin-contact"));
const AdminFaq = lazy(() => import("../pages/adminContainer/admin-faq"));
const AdminFaqCategory = lazy(() =>
  import("../pages/adminContainer/admin-faq-category")
);
const AdminLibrary = lazy(() =>
  import("../pages/adminContainer/admin-library")
);
const AdminSalaqeen = lazy(() =>
  import("../pages/adminContainer/admin-salaqeen")
);
const NotFound = lazy(() => import("../pages/websiteContainer/notFound"));

const adminRoutes = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <DashBoard />,
      },
      {
        path: DASHBOARD,
        element: <DashBoard />,
      },
      {
        path: VIDEO_UPLOAD,
        element: <VideoUpload />,
      },
      {
        path: AUDIO_UPLOAD,
        element: <AudioUpload />,
      },
      {
        path: ADMIN_VIDEO_UPLOAD_CATEGORY,
        element: <VideoUploadCategory />,
      },
      {
        path: FLOOR_PLAN,
        element: <FloorPlan />,
      },
      {
        path: `${MASJID_LIST}/:city/:id`,
        element: <MasjidList />,
      },
      {
        path: CREATE_FLOOR_PLAN,   
        element: <CreateFloorPlan />,
      },
      {
        path: VIEW_FLOOR_PLAN,   
        element: <ViewFloorPlan />,
      },
      {
        path: VIEW_USER_FLOOR_PLAN,   
        element: <ViewUserFloorPlan />,
      },
      {
        path: ITIKAAF_ASSIGNED_SEATS_LIST,   
        element: <ItikaafAssignedFloorList />,
      },
      {
        path: ITIKAAF_ASSIGNED_SEATS,   
        element: <ItikaafAssignedFloor />,
      },
      {
        path: USER_LIST,
        element: <UserList />,
      },
      {
        path: ITIKAAF_APPROVED_LIST,
        element: <ItikaafApprovedList />,
      },
      {
        path: ITIKAAF_ASSIGNED_LIST,
        element: <ItikaafAssignedList />,
      },
      {
        path: ITIKAAF_CONFIRMED_LIST,
        element: <ItikaafConfirmedList />,
      },
      {
        path: ADMIN_CONTACT,
        element: <AdminContact />,
      },
      {
        path: ADMIN_EVENTS,
        element: <AdminEvents />,
      },
      {
        path: ADMIN_FAQ,
        element: <AdminFaq />,
      },
      {
        path: ADMIN_FAQ_CATEGORY,
        element: <AdminFaqCategory />,
      },
      {
        path: ADMIN_LIBRARY,
        element: <AdminLibrary />,
      },
      {
        path: ADMIN_SALAQEEN,
        element: <AdminSalaqeen />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default adminRoutes;
