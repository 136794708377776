import axios from 'axios';
import { LOGIN_API_ROOT } from '../config/constants/apiConstant';

// Create an Axios instance with custom configurations
const myAxiosInstance = axios.create({
  baseURL: LOGIN_API_ROOT, // Set your API base URL
  timeout: 10000, // Set a default timeout (in milliseconds)
  headers: {
    'Content-Type': 'application/json',
    // Add other default headers here
  },
});

// Add an interceptor to handle request configuration before sending
myAxiosInstance.interceptors.request.use(
  (config) => {
    // You can modify the request configuration here
    // For example, add an authorization header
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add an interceptor to handle responses globally
myAxiosInstance.interceptors.response.use(
  (response) => {
    // You can modify the response here
    return response;
  },
  (error) => {
    // You can handle errors globally here
    return Promise.reject(error);
  }
);

// Export the custom Axios instance
export default myAxiosInstance;
