import { createSlice } from '@reduxjs/toolkit';

const activitySlice = createSlice({
   name: 'activity',
   initialState: {
      navPath: null,
      notifiy: false,
      profileDropdown:false,
      access:""
   },
   reducers: {
      navigatePath: (state, { payload }) => {
         state.navPath = payload;
      },
      notification: (state, { payload }) => {
         state.notifiy = payload
      },
      setProfileDropdown: (state, { payload }) => {
         state.profileDropdown = payload;
       },
       setAccess:(state,{payload}) => {
         state.access = payload
       }
   },
});

export const { navigatePath,notification,setProfileDropdown,setAccess } = activitySlice.actions;

export const activitySelector = state => state.activity;
const activityReducer = activitySlice.reducer;
export default activityReducer;
