import React, { useEffect, useRef, useState } from "react";
import generatePDF from "react-to-pdf";
import "./user-list.css";
import DashboardLayout from "../dashboardLayout";
import {
  acceptAndRegisterItakaafUserApi,
  getAssignedUserListApi,
  getMasjidListApi,
  getUserListApi,
} from "../../../api/adminApi";
import { useDispatch, useSelector } from "react-redux";
import { adminSelector } from "../../../redux/slicers/adminSlice";
import { PulseLoader } from "react-spinners";
import Pagination from "../../../components/common/Pagination";
import SelectInput from "../../../components/common/SelectInput";
import DownloadIcon from "../../../assets/images/download-icon.png";
import { ItakaafPdf } from "../../../components/common/pdf";

const ItikaafAssignedList = () => {
  const dispatch = useDispatch();
  const { userLists } = useSelector(adminSelector);
  const [editUser, setEditUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingApi, setLoadingApi] = useState(true);
  const [id, setId] = useState("");
  const [status, setStatus] = useState("pending");
  const [listSize, setListsize] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [limit, setLimit] = useState({ start: 1, end: 3 });
  const [page, setPage] = useState(1);
  const SIZE = 5;
  const { floorPlanLists = [] } = useSelector(adminSelector);
  const [masjidValue, setMasjidValue] = useState(null);
  const [masjidData, setMasjidData] = useState([]);
  const masjidId = masjidValue ? masjidValue: masjidData?.length > 0 ? masjidData[0]._id  : null;
  const [assignedUserList, setAssignedUserList] = useState([]);

  const componentRef = useRef();
  const [downloadAll, setDownloadAll] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [data, setData] = useState({});

  const handleChange = (event) => {
    setMasjidValue(event.target.value);
  };

  const getFloorPlanList = () => {
    dispatch(getMasjidListApi(setLoadingApi, setMasjidData));
  };

  useEffect(() => {
    setLoadingApi(true);
    getFloorPlanList();
  }, []);

  useEffect(() => {
    let value = parseInt(listSize / SIZE) + (listSize % SIZE > 0 ? 1 : 0);
    let pageNumbers = [];
    for (let i = 1; i <= value; i++) {
      pageNumbers.push(i);
    }
    setPageNumbers(pageNumbers);
  }, [listSize, !loadingApi]);

  useEffect(() => {
    if (masjidId) getUserList();
  }, [page, masjidId]);

  const getUserList = () => {
    dispatch(
      getAssignedUserListApi(status, SIZE, setListsize, page, setLoading, masjidId,setAssignedUserList)
    );
  };

  const editRow = (user) => {
    setEditUser(user);
  };
  const handleAcceptReject = (status) => {
    setLoadingBtn(true)
    dispatch(acceptAndRegisterItakaafUserApi(status, id, getUserList,setLoadingBtn));
  };
  const handleDownload = (item) => {
    setDownloadLoading(true);
    setData(item);
    downloadPdf(item)
  };

  const handleDownloadAll = () => {
    setDownloadAll(true);
    downloadPdf({all:true})
  };

  
  const getTargetElement = () => document.getElementById("itakaaf-register");
  
  const downloadPdf = (data={}) => {
    setTimeout(() => {
      const filename = data?.all ? "itakaaf-register" : data?.name
        ? `${data.name}-itakaaf-register`
        : "itakaaf-register";
      const options = {
        filename: `${filename}.pdf`,
        format: 'a3' // Set the format to A3
      };
      generatePDF(getTargetElement, options);
      setDownloadAll(false);
      setDownloadLoading(false);
    }, 300);
  }

  return (
    <DashboardLayout>
      {loadingApi ? (
        <div className="loading-table-wrapper">
          <PulseLoader
            color="#0e5472"
            style={{ display: "flex", justifyContent: "center" }}
          />
        </div>
      ) : (
        <div className="container video-upload p-0 mt-0">
          <div className="title-searchbar">
            <h1>I'tikaf Assigned Seat list</h1>
            <div
              // className="bg-light rounded rounded-pill shadow-sm mb-4 list-search-bar"
              style={{ padding: "0 12px" }}
            >
              <div className="itakaaf-input">
                <SelectInput
                  label=""
                  name="masjidValue"
                  options={masjidData?.map((item) => {
                    return { value: item?._id, label: item?.name };
                  })}
                  handleChange={(e) => {
                    handleChange(e);
                  }}
                  value={masjidValue || ''}
                  error={''}
                  placeHolder="Select Masjid"
                />
                {/* <label>Select Masjid:</label>
                <select value={masjidValue} onChange={handleChange}>
                  <option value="" disabled>
                    Select an option
                  </option>
                  {masjidData?.map((option, index) => (
                    <option key={index} value={option?._id}>
                      {option?.name}
                    </option>
                  ))}
                </select> */}
              </div>
              {/*  <div class="input-group">
              <input
                type="search"
                placeholder="What're you searching.."
                aria-describedby="button-addon1"
                class="form-control border-0 bg-light"
                style={{ padding: "0 12px" }}
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                  style={{ padding: "0 12px" }}
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div> */}
              {/* <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ backgroundColor: "#16aaff", color: "#fff" }}
            >
              Filter By
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>*/}
            </div>
          </div>
          <div className="flex table-lists">
           {assignedUserList?.length > 0 && <div className="btn-wrapper">
              <button className="btn btn-primary" onClick={downloadAll ? undefined : handleDownloadAll}>
                {downloadAll ? (
                  <PulseLoader
                    color="#fff"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px 10px"
                    }}
                  />
                ) : (
                  "Download All"
                )}
              </button>
            </div>}
            <div className="flex-large">
              <table class="table" style={{ border: "1px solid #e9ecef" }}>
                <thead>
                  <tr>
                    <th style={{ width: "30%" }}>Name</th>
                    <th style={{ width: "15%" }}>Age</th>
                    <th style={{ width: "30%" }}>Email</th>
                    <th style={{ width: "25%" }}></th>
                  </tr>
                </thead>
                {loading ? (
                  <tr className="loading-tr">
                    <div className="loading-table-wrapper">
                      <PulseLoader
                        color="#0e5472"
                        style={{ display: "flex", justifyContent: "center" }}
                      />
                    </div>
                  </tr>
                ) : assignedUserList?.length > 0 ? (
                  assignedUserList?.map((item) => (
                    <tbody>
                      <tr>
                        <td>{item?.email}</td>
                        <td>{item?.name}</td>
                        <td>{item?.age}</td>
                        <td>{item?.email}</td>
                        <td>
                          <div
                            className={"button-div"}
                            onClick={() => handleDownload(item)}
                          >
                            {downloadLoading && data?._id == item?._id ? (
                              <PulseLoader
                                color="#0e5472"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              />
                            ) : (
                              <img
                                src={DownloadIcon}
                                alt="DownloadIcon"
                                width={"20px"}
                              />
                            )}
                            {/* </button> */}

                            {/* <button
                              className="btn delete-btn btn-success"
                              data-toggle="modal"
                              data-target="#AcceptModel"
                              onClick={() => setId(item?._id)}
                            >
                              Accept
                            </button>
                            <button
                              className="btn delete-btn btn-danger"
                              data-toggle="modal"
                              data-target="#RejectModel"
                              onClick={() => setId(item?._id)}
                            >
                              Reject
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  
                  <tr>
                    <td colSpan={4}>
                      <h1 style={{textAlign:"center"}}>No data found</h1>
                    </td>
                  </tr>
                )}
              </table>
              <div className="customer_page">
                <div className="pagination">
                  <Pagination
                    setPage={setPage}
                    limit={limit}
                    pageNumbers={pageNumbers}
                    setLimit={setLimit}
                    page={page}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: "fixed", top: "200vh" }}>
            <div ref={componentRef}>
              <ItakaafPdf datas={downloadAll ? assignedUserList : [data]} downloadAll={downloadAll ? true : false} />
            </div>
          </div>
          {/* View pop started */}
          <div
            class="modal fade"
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog shadow-none modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    User details
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div class="modal-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td style={{ width: "50%" }}>
                          Status<span style={{ float: "right" }}>:</span>
                        </td>
                        <td>{editUser?.status}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          Name <span style={{ float: "right" }}>:</span>
                        </td>
                        <td>John Doe{editUser?.name}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          Age <span style={{ float: "right" }}>:</span>
                        </td>
                        <td>34{editUser?.age}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          City <span style={{ float: "right" }}>:</span>
                        </td>
                        <td>Chennai{editUser?.city}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          State <span style={{ float: "right" }}>:</span>
                        </td>
                        <td>Tamil Nadu{editUser?.state}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          Country <span style={{ float: "right" }}>:</span>
                        </td>
                        <td>India{editUser?.country}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-primary" data-dismiss="modal">
                    Yes
                  </button>

                  <button
                    class="btn btn-danger"
                    type="button"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Accept pop started */}
          <div
            class="modal fade"
            id="AcceptModel"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog shadow-none modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Accept user
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div class="modal-footer">
                  <button
                    class="btn btn-primary"
                    onClick={() => handleAcceptReject("accept")}
                    data-dismiss="modal"
                  >
                    {loadingBtn ? (
                      <PulseLoader
                        color="#fff"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      />
                    ) : (
                      "Yes"
                    )}
                  </button>

                  <button
                    class="btn btn-danger"
                    type="button"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Accept pop wrapped */}

          {/* Reject pop started */}
          <div
            class="modal fade"
            id="RejectModel"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog shadow-none modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Reject the user
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div class="modal-footer">
                  <button
                    class="btn btn-primary"
                    onClick={() => handleAcceptReject("reject")}
                    data-dismiss="modal"
                  >
                    {loadingBtn ? (
                      <PulseLoader
                        color="#fff"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      />
                    ) : (
                      "Yes"
                    )}
                  </button>

                  <button
                    class="btn btn-danger"
                    type="button"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Reject pop wrapped */}
        </div>
      )}
    </DashboardLayout>
  );
};

export default ItikaafAssignedList;
