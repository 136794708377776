import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/reset.css";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import ActivityContainer from "./components/common/container/Activity";
import { useRoutes } from "react-router-dom";
import { getRoutes } from "./routes";
import { getLocal } from "./helpers/projectHelper";
import LazyLoader from "./components/common/LazyLoader";
import { activitySelector, setAccess } from "./redux/slicers/activitySlice";

function App() {
  const dispatch = useDispatch();
  const { access } = useSelector(activitySelector);

  useEffect(() => {
    dispatch(setAccess(getLocal()));
  }, []);

  const router = useRoutes(getRoutes(access ? "admin" : "user"));

  return (
    <>
      <ActivityContainer>
        <LazyLoader>{router}</LazyLoader>
        <ToastContainer />
      </ActivityContainer>
    </>
  );
}

export default App;
