import { SUB_ADMIN, SUPER_ADMIN, WEBSITE_RELOGIN } from "./routePathConstants";

export const DEFAULT_TOKEN = "token";
export const USER_DATA = "userData";
export const USER_DETAILS = "user_deatils";
export const DEBOUNCE_DELAY = 500;

//roles
export const SUPER_ADMIN_ROLE = "super-admin";

//role navigation
export const ROLE_NAV = {
  ADMIN: SUPER_ADMIN,
  SUBADMIN: SUB_ADMIN,
};

export const PREVENT_ROUTES = [WEBSITE_RELOGIN];

export const NETWORK_ERROR = "Network Error";
export const USER_TYPE_LOGIN = {
  super_admin: SUPER_ADMIN,
  ADMIN: SUPER_ADMIN,
};
