import { lazy } from "react";
import { Outlet } from "react-router-dom";
import {
  ITAKAAF_REGISTRATION,
  SALAQEEN_REGISTRATION,
  VIDEO,
  VIDEO_CATEGORY,
  ABOUT_ME,
  ADMIN_LOGIN,
  MORE_BAYAAN,
  EVENTS,
  FAQ,
  CONTACT_US,
  LIBRARY,
  CONTRIBUTION,
  AUDIO,
  SHAJRAH,
  // QUERY,
  PROGRAM_REQUEST_FORM,
  USER_CONFIRMATION_FORM
} from "../config/constants/routePathConstants";
import Shajrah from "../pages/websiteContainer/shajrah";

const Home = lazy(() => import("../pages/websiteContainer/home"));
const ItakaafRegister = lazy(() =>
  import("../pages/websiteContainer/itakaaf-register")
);
const SalaqeenRegister = lazy(() =>
  import("../pages/websiteContainer/salaqeen-register")
);
const AdminLogin = lazy(() => import("../pages/adminContainer/login"));
const VideoPage = lazy(() => import("../pages/websiteContainer/video"));
const AudioPage = lazy(() => import("../pages/websiteContainer/audio"));
const AudioCategoryPage = lazy(() => import("../pages/websiteContainer/audio/category"));
const VideoCategoryPage = lazy(() =>
  import("../pages/websiteContainer/video/category")
);
const AboutMe = lazy(() => import("../pages/websiteContainer/about-me"));
const MoreBayaan = lazy(() => import("../pages/websiteContainer/more-bayaan"));
const Events = lazy(() => import("../pages/websiteContainer/events"));
const Faq = lazy(() => import("../pages/websiteContainer/faq"));
const NotFound = lazy(() => import("../pages/websiteContainer/notFound"));
const ContactUs = lazy(() => import("../pages/websiteContainer/contact-us"));
const Library = lazy(() => import("../pages/websiteContainer/library"));
const Contribution = lazy(() =>
  import("../pages/websiteContainer/contribution")
);
const ProgramRegisterForm = lazy(() => import("../pages/websiteContainer/program-register-form"));
const ItikafUserConfirmation = lazy(() => import("../pages/websiteContainer/itikaf-user-confirmation"));

const userRoutes = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: ADMIN_LOGIN,
        element: <AdminLogin />,
      },
      {
        path: ITAKAAF_REGISTRATION,
        element: <ItakaafRegister />,
      },
      {
        path: SALAQEEN_REGISTRATION,
        element: <SalaqeenRegister />,
      },
      {
        path: VIDEO,
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <VideoPage />,
          },
          {
            path: ":id",
            element: <VideoCategoryPage />,
          },
        ],
      },
      {
        path: VIDEO_CATEGORY,
        element: <VideoCategoryPage />,
      },
      {
        path: MORE_BAYAAN,
        element: <MoreBayaan />,
      },
      {
        path: ABOUT_ME,
        element: <AboutMe />,
      },
      {
        path: EVENTS,
        element: <Events />,
      },
      {
        path: SHAJRAH,
        element: <Shajrah />,
      },
      {
        path: AUDIO,
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <AudioPage />,
          },
          {
            path: ":id",
            element: <AudioCategoryPage />,
          },
        ],
      },
      {
        path: CONTRIBUTION,
        element: <Contribution />,
      },
      {
        path: FAQ,
        element: <Faq />,
      },
      {
        path: CONTACT_US,
        element: <ContactUs />,
      },
      {
        path: LIBRARY,
        element: <Library />,
      },
      {
        path: PROGRAM_REQUEST_FORM,
        element: <ProgramRegisterForm />,
      },
      {
        path: `${USER_CONFIRMATION_FORM}/:id`,
        element: <ItikafUserConfirmation />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default userRoutes;
