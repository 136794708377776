import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    liveBroadCasts: [],
    liveBroadCastCategories: [],
    liveBroadCastSubCategories: [],
    messageLists:[],
    salaqeenLists: [],
    faqLists: [],
    faqCategoryLists: [],
    singleLiveBroadCast: {},
    userLists: [],
    eventLists: [],
    bookLists: [],
    floorPlanLists: [],
    floorPlanListsById: [],
  },
  reducers: {
    getLiveBroadCasts: (state, { payload }) => {
      state.liveBroadCasts = payload;
    },
    getLiveBroadCastCategories: (state, { payload }) => {
      state.liveBroadCastCategories = payload;
    },
    getLiveBroadCastSubCategories: (state, { payload }) => {
      state.liveBroadCastSubCategories = payload;
    },
    getSingleLiveBroadCast: (state, { payload }) => {
      state.singleLiveBroadCast = payload;
    },
    getUserLists: (state, { payload }) => {
      state.userLists = payload;
    },
    getSalaqeenLists: (state, { payload }) => {
      state.salaqeenLists = payload;
    },
    getFaqLists: (state, { payload }) => {
      state.faqLists = payload;
    },
    getFaqCategoryLists: (state, { payload }) => {
      state.faqCategoryLists = payload;
    },
    getEventLists: (state, { payload }) => {
      state.eventLists = payload;
    },
    getBookLists: (state, { payload }) => {
      state.bookLists = payload;
    },
    getMessageLists: (state, { payload }) => {
      state.messageLists = payload;
    },
    getFloorPlanLists: (state, { payload }) => {
      state.floorPlanLists = payload;
    },
    getFloorPlanListsById: (state, { payload }) => {
      state.floorPlanListsById = payload;
    },
  },
});

export const {
  getLiveBroadCasts,
  getLiveBroadCastCategories,
  getLiveBroadCastSubCategories,
  getSingleLiveBroadCast,
  getUserLists,
  getSalaqeenLists,
  getFaqLists,
  getFaqCategoryLists,
  getEventLists,
  getBookLists,
  getMessageLists,
  getFloorPlanLists,
  getFloorPlanListsById
} = adminSlice.actions;

export const adminSelector = (state) => state.admin;
const adminReducer = adminSlice.reducer;
export default adminReducer;
