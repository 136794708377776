import React, { useEffect, useState } from "react";
import "./style.css";
import "../user-list/user-list.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCityBasedUserList,
  getFloorPlanListApi,
} from "../../../api/adminApi";
import { adminSelector } from "../../../redux/slicers/adminSlice";
import DashboardLayout from "../dashboardLayout";
import { PulseLoader } from "react-spinners";
import Pagination from "../../../components/common/Pagination";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ViewFloorPlan from "./ViewUserFloorPlan";
import { errorToast } from "../../../helpers/projectHelper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MasjidList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userLists = [] } = useSelector(adminSelector);
  const [loading, setLoading] = useState(true);
  // const [id, setId] = useState("");
  const id = pathname;
  const cityName = pathname.split("/")[3];
  const masjidId = pathname.split("/")[4];

  const [listSize, setListsize] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [limit, setLimit] = useState({ start: 1, end: 3 });
  const [page, setPage] = useState(1);
  const SIZE = 5;
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [listStatus, setListStatus] = useState("unassigned");

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const allItemIds = userLists.map((item) => item?._id);
      setCheckedItems(allItemIds);
    } else {
      setCheckedItems([]);
    }
  };

  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, itemId]);
    } else {
      setCheckedItems(checkedItems.filter((id) => id !== itemId));
    }
  };

  const handleClickOpen = () => {
    if (checkedItems.length === 0) {
      // Send error toaster message
      errorToast("Please check at least one user");
    } else {
      setOpen(true);
    }
  };

  const handleListStatus = (status) => {
    setListStatus(status);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let value = parseInt(listSize / SIZE) + (listSize % SIZE > 0 ? 1 : 0);
    let pageNumbers = [];
    for (let i = 1; i <= value; i++) {
      pageNumbers.push(i);
    }
    setPageNumbers(pageNumbers);
  }, [listSize]);

  const getFloorPlanList = () => {
    dispatch(
      getCityBasedUserList(
        cityName,
        masjidId,
        SIZE,
        setListsize,
        page,
        setLoading,
        listStatus
      )
    );
  };

  useEffect(() => {
    getFloorPlanList();
  }, [page, listStatus]);

  return (
    <>
      <DashboardLayout>
        <div className=" video-upload">
          <div className="title-video-upload">
            <h1>Confirmed City Based User List</h1>
            <div className="book-wrap-btn">
              <Button variant="contained" className="back-btn" onClick={() => navigate(-1)}>
                <i class="fa-solid fa-arrow-left"></i> Back
              </Button>
              <Button variant="outlined" onClick={handleClickOpen}>
                Book
              </Button>
            </div>
          </div>
          <div className="flex table-lists">
            <div className="flex-large cnfrm-btn-wrap">
              <Button
                variant={listStatus === "unassigned" ? "contained" : "outlined"}
                onClick={() => handleListStatus("unassigned")}
              >
                Yet to Assign Seat
              </Button>
              <Button
                variant={listStatus === "assigned" ? "contained" : "outlined"}
                onClick={() => handleListStatus("assigned")}
              >
                Already Seat Assigned
              </Button>
            </div>
            <div className="flex-large">
              <table class="table" style={{ border: "1px solid #e9ecef" }}>
                <thead>
                  <tr>
                    {listStatus !== "assigned" && (
                      <th style={{ width: "10%" }}>
                        {" "}
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                          disabled={listStatus === "assigned" ? true : false}
                        />
                      </th>
                    )}
                    <th style={{ width: "20%" }}>Name</th>
                    <th style={{ width: "15%" }}>Age</th>
                    <th style={{ width: "30%" }}>Email </th>
                    {/* <th style={{ width: "30%" }}>Status</th> */}
                    <th style={{ width: "25%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="loading-tr">
                      <div className="loading-table-wrapper">
                        <PulseLoader
                          color="#0e5472"
                          style={{ display: "flex", justifyContent: "center" }}
                        />
                      </div>
                    </tr>
                  ) : userLists?.length > 0 ? (
                    userLists?.map((item) => (
                      <tr key={item?._id}>
                        {listStatus !== "assigned" && (
                          <td>
                            <input
                              type="checkbox"
                              checked={checkedItems.includes(item?._id)}
                              onChange={(event) =>
                                handleCheckboxChange(event, item?._id)
                              }
                              disabled={
                                listStatus === "assigned" ? true : false
                              }
                            />
                          </td>
                        )}
                        <td>{item?.name}</td>
                        <td>{item?.age}</td>
                        <td>{item?.email}</td>
                        {/* <td>
                        <div className={"button-div"}>
                          <button
                            className="btn edit-btn btn-primary"
                            type="button"
                            class="btn btn-primary"
                            onClick={() => {
                              navigate(
                                `/admin/itikaaf-confirmed-list/seat/${item?.city}/${item?.masjid}`
                              );
                            }}
                          >
                            View
                          </button>
                        </div>
                      </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <h1 style={{ textAlign: "center" }}>No data found</h1>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="customer_page">
                <div className="pagination">
                  <Pagination
                    setPage={setPage}
                    limit={limit}
                    pageNumbers={pageNumbers}
                    setLimit={setLimit}
                    page={page}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
      <React.Fragment>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar sx={{ justifyContent: "flex-end" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <i class="fa-solid fa-xmark"></i>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Close
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
          <ViewFloorPlan
            checkedItems={checkedItems}
            isPopup={true}
            handleClose={handleClose}
            getFloorPlanList={getFloorPlanList}
          />
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default MasjidList;
