export const shajrahData = [
  { title: "(1) Genealogy of Sayyed Muhammad Talha Qasmi DB (Fazil Dar Uloom Deoband)"},
  { title: "(2) Nafis Akbar Owais (Student of Shaykh-ul-Islam Husain Ahmad al-Madani, (May Allah Have Mercy on Him)"},
  { title: "(3) Abdul Hameed (Student of Hakeem-ul-Ummat, Ashraf Ali Thanwi (May Allah Have Mercy on Him)"},
  { title: "(4) Abd-ul-Ahad"},
  { title: "(5) Muhammad Akbar Kazmi"},
  { title: "(6) Safdar Husain"},
  { title: "(7) Asghar Ali"},
  { title: "(8) Maqsood Ali"},
  { title: "(9) Yaar Muhammad"},
  { title: "(10) Abu Muhammad"},
  { title: "(11) Muhammad Waris"},
  { title: "(12) Ghulam Aalam"},
  { title: "(13) Muhammad Sadiq"},
  { title: "(14) Muhammad Akram"},
  { title: "(15) Shah Muhammad Sadiq"},
  { title: "(16) Bandgi Shah Farid"},
  { title: "(17) Shah Ashraf"},
  { title: "(18) Muhammad Ali"},
  { title: "(19) Mahmood Ali"},
  { title: "(20) Nizamuddin"},
  { title: "(21) Husain (Syed Husain Mufti-ul-Jinn wal ins)"},
  { title: "(22) Abul Qasim"},
  { title: "(23) Alauddin"},
  { title: "(24) Bahauddin"},
  { title: "(25) Jalaluddin"},
  { title: "(26) Abdul Haq"},
  { title: "(27) Abul Khair"},
  { title: "(28) Qutubuddin Musavi"},
  { title: "(29) Abdullah"},
  { title: "(30) Muhammad Qasim"},
  { title: "(31) Muhammad Isma'il"},
  { title: "(32) Ziauddin"},
  { title: "(33) Ali Qasim"},
  { title: "(34) Muhammad Hashim"},
  { title: "(35) Abul Qasim"},
  { title: "(36) Tahir"},
  { title: "(37) Tayyib"},
  { title: "(38) Muhammad"},
  { title: "(39) Musa Kazim"},
  { title: "(40) Jafar Sadiq"},
  { title: "(41) Muhammad Baqir"},
  { title: "(42) Ali Zainul Aabideen"},
  { title: "(43) Husain (Grand son of the Messenger of Allah, May Allah Bless Him and Grant Him Peace)"},
  { title: "(44) Ali bin Abu Talib (May Allah Be Pleased with Him)."},
];
