import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    signUp: false,
    reltimeAppPopup: false,
    liveBroadcastPlaylist: [],
    liveBroadcastSubPlaylist: [],
    walletDetailsList: [],
    allEventList: [],
    bookLists: [],
    recentEventList: [],
    upComingEventList: [],
    myEarnings: {},
    jointAccountList: [],
    userDashboard: [],
    singleAudio: {},
    audioLists: [],
    allAudioLists: [],
    subPlaylistAudio: [],
    preOrder: {},
  },
  reducers: {
    getSignUpPopUP: (state, { payload }) => {
      state.signUp = payload;
    },
    getReltimeAppPopup: (state, { payload }) => {
      state.reltimeAppPopup = payload;
    },
    getWalletDetailsList: (state, { payload }) => {
      state.walletDetailsList = payload;
    },
    getMyEarnings: (state, { payload }) => {
      state.myEarnings = payload;
    },
    getJointAccountList: (state, { payload }) => {
      state.jointAccountList = payload;
    },
    getuserDashboard: (state, { payload }) => {
      state.userDashboard = payload;
    },
    preOrderCheck: (state, { payload }) => {
      state.preOrder = payload;
    },
    getAllEventList: (state, { payload }) => {
      state.allEventList = payload;
    },
    getRecentEventList: (state, { payload }) => {
      state.recentEventList = payload;
    },
    getUpComingEventList: (state, { payload }) => {
      state.upComingEventList = payload;
    },
    getLiveBroadcastPlaylist: (state, { payload }) => {
      state.liveBroadcastPlaylist = payload;
    },
    getLiveBroadcastSubPlaylist: (state, { payload }) => {
      state.liveBroadcastSubPlaylist = payload;
    },
    getListOfBooks: (state, { payload }) => {
      state.bookLists = payload;
    },
    getSingleAudio: (state, { payload }) => {
      state.singleAudio = payload;
    },
    getAudioLists: (state, { payload }) => {
      state.audioLists = payload;
    },
    getSubPlaylistAudio: (state, { payload }) => {
      state.subPlaylistAudio = payload;
    },
    getAllAudioLists: (state, { payload }) => {
      state.allAudioLists = payload;
    },
  },
});

export const {
  getAudioLists,
  getSubPlaylistAudio,
  getSingleAudio,
  getAllAudioLists,
  getSignUpPopUP,
  getReltimeAppPopup,
  getWalletDetailsList,
  getMyEarnings,
  getJointAccountList,
  getuserDashboard,
  preOrderCheck,
  getAllEventList,
  getRecentEventList,
  getUpComingEventList,
  getLiveBroadcastPlaylist,
  getLiveBroadcastSubPlaylist,
  getListOfBooks,
} = userSlice.actions;

export const userSelector = (state) => state.user;
const userReducer = userSlice.reducer;
export default userReducer;
