// Website Route

export const WEBSITE = "/*";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const ADMIN_LOGIN = "/admin-login";
export const HOME = "/";
export const DEVICE_INFO = "/deviceinfo";
export const ABOUT = "/about";
export const EVENTS = "/events";
export const SHAJRAH = "/shajrah";
export const CONTRIBUTION = "/contribution";
export const FAQ = "/faq";
export const MORE_BAYAAN = "/more-bayaan";
export const ITAKAAF_REGISTRATION = "/itakaaf-registration";
export const SALAQEEN_REGISTRATION = "/salaqeen-registration";
export const VIDEO = "/video";
export const AUDIO = "/audio";
export const VIDEO_CATEGORY = "/video/:id";
export const ABOUT_ME = "/about-me";
export const CONTACT_US = "/contact-us";
export const LIBRARY = "/library";
export const PROGRAM_REQUEST_FORM = "/program-registration";
export const USER_CONFIRMATION_FORM = "/user-conformation-form";


//admin
export const DASHBOARD = "/admin/dashboard";
export const VIDEO_UPLOAD = "/admin/video-upload";
export const AUDIO_UPLOAD = "/admin/audio-upload";
export const ADMIN_VIDEO_UPLOAD_CATEGORY = "/admin/video-upload/category";
export const USER_LIST = "/admin/user-list";
export const ITIKAAF_APPROVED_LIST = "/admin/itikaaf-approved-list";
export const ITIKAAF_CONFIRMED_LIST = "/admin/itikaaf-confirmed-list";
export const ITIKAAF_ASSIGNED_LIST = "/admin/itikaaf-assigned-list";
export const FLOOR_PLAN = "/admin/floor-plan";
export const MASJID_LIST = "/admin/city";
export const VIEW_FLOOR_PLAN = "/admin/floor-plan/:id";
export const VIEW_USER_FLOOR_PLAN = "/admin/itikaaf-confirmed-list/seat/:city/:id";
export const ITIKAAF_ASSIGNED_SEATS_LIST = "/admin/itikaaf-assigned-seat";
export const ITIKAAF_ASSIGNED_SEATS = "/admin/itikaaf-assigned-seat/:masjidId";
export const CREATE_FLOOR_PLAN = "/admin/create/floor-plan";
export const ADMIN_FAQ = "/admin/faq";
export const ADMIN_FAQ_CATEGORY = "/admin/faq/category";
export const ADMIN_EVENTS = "/admin/events";
export const ADMIN_LIBRARY = "/admin/library";
export const ADMIN_SALAQEEN = "/admin/salaqeen";
export const ADMIN_CONTACT = "/admin/contact";

export const WEBSITE_RELOGIN = "/re-login";
export const SUPER_ADMIN = "/super-admin";
export const ADMIN = "/admin";
export const SUB_ADMIN = "/sub-admin";
