import { combineReducers, configureStore } from '@reduxjs/toolkit';
import sessionReducer from './slicers/sessionSlice.js';
import activityReducer from './slicers/activitySlice.js';
import userReducer from "./slicers/userSlice.js";
import  adminReducer from "./slicers/adminSlice"

const rootReducer = combineReducers({
  activity: activityReducer,
  session: sessionReducer,
  user: userReducer,
  admin: adminReducer,
});

const store = configureStore({ reducer: rootReducer });
export default store;
