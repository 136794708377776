import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./style.css";
import logo from "../../../assets/images/logo.png";
import aetikaafImg from "../../../assets/images/itakaaf.png";
import nafilImage from "../../../assets/images/pdf/itekafYellow.png"
import sunnathImage from "../../../assets/images/pdf/itekafBlack.png"
import { fileDownloadApi } from "./../../../api/Sessions";
import { type } from "@testing-library/user-event/dist/type";

export const handleDownloadPDF = async (
  setBtnLoading,
  componentRef,
  data = {}
) => {
  setBtnLoading(true);
  const doc = new jsPDF("a3", "px", [
    componentRef.current.offsetWidth,
    componentRef.current.offsetHeight,
  ]);

  const scaleFactor = 1;
  //   const fontFile = "../../../assets/fonts/ClashDisplay-Regular.ttf";

  //   doc.addFileToVFS(fontFile, HindSiliguriRegular);
  //   doc.addFont(fontFile, "Hind Siliguri", "normal");
  //   doc.setFont("Hind Siliguri");

  doc.setPage(1);

  const canvas = await html2canvas(componentRef.current, {
    scale: scaleFactor,
  });

  const imgData = canvas.toDataURL("image/jpeg", 2);

  doc.addImage(
    imgData,
    "jpeg",
    0,
    0,
    doc.internal.pageSize.getWidth(),
    doc.internal.pageSize.getHeight()
  );

  const filename = data?.name
    ? `${data.name}-itakaaf-register`
    : "itakaaf-register";
  doc.save(`${filename}.pdf`);

  setBtnLoading(false);
};

export const ItakaafPdf = ({ datas, downloadAll }) => {
  return (
    <div
      id="itakaaf-register"
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        width: downloadAll? "3508px": "300px", // A3 width
        height: downloadAll? "4960px": "400px", // A3 height
      }}
    >
      {datas?.map((data) => {
        const {
          name = "",
          city = "",
          contactNumber = "",
          floor,
          row,
          seat,
          photo = "",
        } = data;

        return (
          <>
            <div className="itikaaf-card" style={{ backgroundImage: `url(${data?.nafilDays == "10"? sunnathImage : nafilImage })` }}>
              <div className="item photo">
                {photo ? (
                  <img src={fileDownloadApi(photo)} alt="profile" />
                ) : (
                  <img src={logo} alt="profile" />
                )}
              </div>
              <p className="item user-id">
                <b>{data?.name}</b>
              </p>
              <p className="item seat-id">
                <b>{data?.id}</b>
              </p>
              <p className="item seat-no">
                <b>{`${row?.rowName ?? ""}${seat?.number ?? ""}`}</b>
              </p>
              <p className="item city">
                <b>{city ? city : "NA"}</b>
              </p>
              <p className="item contact">
                <b>{contactNumber ? contactNumber : "NA"}</b>
              </p>
            </div>
          </>
        );
      })}
    </div>
  );
};

// old card design
// <div className="card-wrapper">
//   <div className="photo-sec">
//     <div className="left-logo">
//       <img src={aetikaafImg} alt="" />
//     </div>
//     {photo ? (
//       <div className="photo">
//         <img src={fileDownloadApi(photo)} alt="profile" />
//       </div>
//     ) : (
//       <div className="photo">
//         <img src={logo} alt="profile" />
//       </div>
//     )}
//     <div className="right-logo">
//       <img src={logo} alt="" />
//     </div>
//   </div>
//   <div className="data-sec">
//     <div className="personal-data">
//       <div>
//         <span>
//           Floor NO :
//           <span className="number">
//             <b>{floor?.floorName}</b>
//           </span>
//         </span>
//       </div>
//       <div>
//         <span>
//           MUTAKIF NO :
//           <span className="number">
//             <b>{seat?._id}</b>
//           </span>
//         </span>
//       </div>
//       <div>
//         <span>
//           BED NO :
//           <span className="number">
//             <b>{`${row?.rowName}${seat?.number}`}</b>
//           </span>
//         </span>
//       </div>
//     </div>
//     <div className="personal-data">
//       <div>
//         <p>Name</p>
//         <p>:</p>
//         <p>
//           <b>{name ? name : "NA"}</b>
//         </p>
//       </div>
//       <div>
//         <p>CITY</p>
//         <p>:</p>
//         <p>
//           <b>{city ? city : "NA"}</b>
//         </p>
//       </div>
//       <div>
//         <p>MOBILE</p>
//         <p>:</p>
//         <p>
//           <b>{contactNumber ? contactNumber : "NA"}</b>
//         </p>
//       </div>
//     </div>
//   </div>
//   <div className="signature-sec">
//     <p className="tar-tex">{"Tariqath Team"}</p>
//     <p className="tex">SIGNATURE OF AUTHORITY</p>
//     <br />
//   </div>
// </div>;
