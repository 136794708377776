import React, { useEffect, useState } from "react";
import { getFloorPlanListByIdApi } from "../../../api/adminApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../dashboardLayout";
import { adminSelector } from "../../../redux/slicers/adminSlice";
import "./floor-plan.css";
import { ReactComponent as SeatIcon } from "../../../assets/images/floor/mosqueIcon.svg";
import { Button } from "@mui/material";
// const Seat = ({ seat, rowName, onSelectSeat, selectedSeat }) => {
//   const seatStyle = {
//     border: '1px solid #ccc',
//     padding: '10px',
//     margin: '5px',
//     backgroundColor: selectedSeat?.includes(seat?._id) ? 'blue' : seat?.registeredUser ? 'red' : 'green',
//     cursor: 'pointer',
//   };
// const YOUR_SELECTED_COUNT_CONSTANT= 5;
//   const handleSeatClick = () => {
//     const updatedSelectedSeats = Array.isArray(selectedSeat) ? [...selectedSeat] : [];

//     if (seat?._id) {
//       // If the seat is already selected, remove it
//       if (updatedSelectedSeats?.includes(seat?._id)) {
//         updatedSelectedSeats?.splice(updatedSelectedSeats.indexOf(seat?._id), 1);
//       } else {
//         // If a specific count is not reached, add the seat to the selectedSeat array
//         if (updatedSelectedSeats.length < YOUR_SELECTED_COUNT_CONSTANT) {
//           updatedSelectedSeats.push(seat?._id);
//         }
//       }

//       onSelectSeat(updatedSelectedSeats);
//     }
//   };

//   return (
//     <div style={seatStyle} onClick={handleSeatClick}>
//       {rowName}
//       {seat?.number}
//     </div>
//   );
// };

const Seat = ({ seat, rowName, onSelectSeat, selectedSeat }) => {
  const seatStyle = {
    backgroundColor: selectedSeat?.includes(seat?._id)
      ? "blue"
      : seat?.registeredUser
      ? "red"
      : "#001232",
    // : "green",
  };

  const handleSeatClick = () => {
    const updatedSelectedSeats = Array.isArray(selectedSeat)
      ? [...selectedSeat]
      : [];
    if (seat?._id) {
      if (updatedSelectedSeats.includes(seat._id)) {
        // Seat is already selected, remove it
        updatedSelectedSeats.splice(updatedSelectedSeats.indexOf(seat._id), 1);
      } else {
        // Seat is not selected, add it
        updatedSelectedSeats.push(seat._id);
      }

      onSelectSeat(updatedSelectedSeats);

      // If you want to update the color immediately, you can comment out the onSelectSeat line and update the state directly
      // setSelectedSeats(updatedSelectedSeats);
    }
  };

  return (
    <div className="seat-icon" onClick={handleSeatClick}>
      <div
        className={`seat-icon-info ${
          selectedSeat?.includes(seat?._id)
            ? "selected"
            : seat?.registeredUser
            ? "active"
            : ""
        }`}
      >
        <SeatIcon />
        <div className="row-name">
          {rowName}
          {seat?.number}
        </div>
      </div>
    </div>
  );
};

const Row = ({ row, onSelectSeat, selectedSeat }) => {
  return (
    <div className="seat-view-wrapper">
      {row.seats.map((seat) => (
        <Seat
          key={seat._id}
          seat={seat}
          rowName={row?.rowName}
          onSelectSeat={onSelectSeat}
          selectedSeat={selectedSeat}
        />
      ))}
    </div>
  );
};

const Floor = ({ floor, onSelectSeat, selectedSeat }) => {
  return (
    <div className="floor-wrapper">
      <h2>
        {floor.floorName}
        {/* Floor: <span>{floor.floorName}</span> */}
      </h2>
      {/* <p>
        Capacity: <span>{floor.capacity}</span>
      </p> */}
      <div className="view-floor-wrap">
        {floor.rows.map((row) => (
          <Row
            key={row._id}
            row={row}
            onSelectSeat={onSelectSeat}
            selectedSeat={selectedSeat}
          />
        ))}
      </div>
    </div>
  );
};

const MasjidDetails = ({ masjid,navigate }) => {
  const [selectedSeat, setSelectedSeat] = useState(null);

  const handleSelectSeat = (seatId) => {
    setSelectedSeat(seatId);
  };

  return (
    <div className="view-floor-wrapper">
      <div className="back-btn-wrap">
        <Button
          variant="contained"
          className="back-btn"
          onClick={() => navigate(-1)}
        >
          <i class="fa-solid fa-arrow-left"></i> Back
        </Button>
      </div>
      <h1>Masjid Details</h1>
      <h2>
        <span>{masjid?.name}</span>
        <span className="city">{masjid?.city ? `(${masjid?.city})` : ""}</span>
      </h2>
      {/* <p>
        City: <span>{masjid?.city}</span>
      </p> */}
      {masjid?.floors?.map((floor) => (
        <Floor
          key={floor._id}
          floor={floor}
          onSelectSeat={handleSelectSeat}
          selectedSeat={selectedSeat}
        />
      ))}
      {/* {selectedSeat && <p>Selected Seat: {selectedSeat}</p>} */}
    </div>
  );
};

const ViewFloorPlan = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const { floorPlanListsById = [] } = useSelector(adminSelector);
  // const { pathname } = location
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getFloorPlanListById();
  }, []);
  const getFloorPlanListById = () => {
    dispatch(getFloorPlanListByIdApi(id, setLoading));
  };
  return (
    <DashboardLayout>
      <MasjidDetails masjid={floorPlanListsById} navigate={navigate}/>
    </DashboardLayout>
  );
};

export default ViewFloorPlan;
