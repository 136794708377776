import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  EVENTS,
  FAQ,
  HOME,
  ITAKAAF_REGISTRATION,
  SALAQEEN_REGISTRATION,
  VIDEO,
  CONTACT_US,
  LIBRARY,
  CONTRIBUTION,
  AUDIO,
  SHAJRAH,
  // QUERY,
  PROGRAM_REQUEST_FORM,
} from "../../../config/constants/routePathConstants";
import Logo from "../../../assets/images/logo.png";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <nav
      className="navbar-container navbar navbar-expand-lg navbar-light bg-light fixed-top"
      onClick={() => setOpen(false)}
    >
      {/* <a className="navbar-brand" href="#">
        Navbar
      </a> */}
      <div className="mb-logo" style={{ width: "40px", zIndex: "51" }} onClick={() => navigate("/")}>
        <img src={Logo} alt="logo" style={{ width: "100%" }} />
      </div>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {open ? (
          <i class="fa fa-times" aria-hidden="true"></i>
        ) : (
          <i class="fa fa-bars" aria-hidden="true"></i>
        )}
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={Logo} alt="logo" style={{ width: "100%" }} />
        </div>
        <ul className="navbar-nav">
          <li className={`nav-item ${pathname == HOME ? "active" : ""}`}>
            <Link className="nav-link" to={HOME}>
              Home
            </Link>
          </li>
          <li className={`nav-item ${pathname == SHAJRAH ? "active" : ""}`}>
            <Link className="nav-link" to={SHAJRAH}>
              Shajrah
            </Link>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Bayaan
            </a>
            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <Link className="dropdown-item" to={VIDEO}>
                Video
              </Link>
              <Link className="dropdown-item" to={AUDIO}>
                Audio
              </Link>
            </div>
          </li>
          <li className={`nav-item ${pathname == EVENTS ? "active" : ""}`}>
            <Link className="nav-link" to={EVENTS}>
              Events
            </Link>
          </li>
          <li className={`nav-item ${pathname == LIBRARY ? "active" : ""}`}>
            <Link className="nav-link" to={LIBRARY}>
              Library
            </Link>
          </li>
          <li className={`nav-item ${pathname == FAQ ? "active" : ""}`}>
            <Link className="nav-link" to={FAQ}>
              FAQ's
            </Link>
          </li>
          <li
            className={`nav-item ${pathname == CONTRIBUTION ? "active" : ""}`}
          >
            <Link className="nav-link" to={CONTRIBUTION}>
              Contribution
            </Link>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Register
            </a>
            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <Link className="dropdown-item" to={SALAQEEN_REGISTRATION}>
                Salikeen Registration
              </Link>
              <Link className="dropdown-item" to={ITAKAAF_REGISTRATION}>
                I'tikaf Registration
              </Link>
              <Link className="dropdown-item" to={PROGRAM_REQUEST_FORM}>
                Program Request From
              </Link>
            </div>
          </li>
          {/* <li className={`nav-item ${pathname == QUERY ? "active" : ""}`}>
            <Link className="nav-link" to={QUERY}>
              Query
            </Link>
          </li> */}
          <li className={`nav-item ${pathname == CONTACT_US ? "active" : ""}`}>
            <Link className="nav-link" to={CONTACT_US}>
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      <div id="sidebar" className={`neumorphic ${open ? "" : "hide"}`}>
        <ul className="menu">
          <li className="sidebar-menu-li active">
            <Link to={HOME}>Home</Link>
          </li>
          <li className={`nav-item ${pathname == SHAJRAH ? "active" : ""}`}>
            <Link className="sidebar-menu-li" to={SHAJRAH}>
              Shajrah
            </Link>
          </li>
          <li className="sidebar-menu-li drop-down">
            {/* <Link to={HOME}>Bayaan</Link> */}
            <div>
              <span>Bayaan</span>
              <span>
                <i class="fa-sharp fa-solid fa-caret-down"></i>
              </span>
            </div>
            <ul>
              <li>
                <Link to={VIDEO}>Video</Link>
              </li>
              <li>
                <Link to={AUDIO}>Audio</Link>
              </li>
            </ul>
          </li>
          <li className="sidebar-menu-li">
            <Link to={EVENTS}>Events</Link>
          </li>
          <li className="sidebar-menu-li">
            <Link to={LIBRARY}>Library</Link>
          </li>
          <li className="sidebar-menu-li">
            <Link to={FAQ}>FAQ's</Link>
          </li>
          <li className="sidebar-menu-li">
            <Link to={CONTRIBUTION}>Contribution</Link>
          </li>
          <li className="sidebar-menu-li drop-down">
            {/* <Link to={HOME}>Register</Link> */}
            <div>
              <span>Register</span>
              <span>
                <i class="fa-sharp fa-solid fa-caret-down"></i>
              </span>
            </div>
            <ul>
              <li>
                <Link to={SALAQEEN_REGISTRATION}>Salikeen Registration</Link>
              </li>
              <li>
                <Link to={ITAKAAF_REGISTRATION}>I'tikaf Registration</Link>
              </li>
              <li>
                <Link to={PROGRAM_REQUEST_FORM}>Program Request From</Link>
              </li>
            </ul>
          </li>
          {/* <li className="sidebar-menu-li">
            <Link to={QUERY}>Query</Link>
          </li> */}
          <li className="sidebar-menu-li">
            <Link to={CONTACT_US}>Contact Us</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
