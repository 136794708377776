import React, { useState } from "react";
import Accordian from "../../../components/global/Accordian/Accordian";
import { shajrah } from "../../../components/global/Accordian/AccordianData";
import Navbar from "../home/navbar";
import Footer from "../home/footer";
import "./style.css";
import { useEffect } from "react";
import { shajrahData } from "./data";

const Shajrah = () => {
  const [tabs, setTabs] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className="main-container">
        <div className="shajrah-page">
          <Navbar />
          <div className="title">
            <h1>Shajrah</h1>
          </div>
          <div className="tabs-wrap">
            <button
              className={`btn ${tabs ? "active" : ""}`}
              onClick={() => setTabs(true)}
            >
              Silsila-e-Aaliya Naqshbandiya
            </button>
            <button
              className={`btn ${tabs ? "" : "active"}`}
              onClick={() => setTabs(false)}
            >
              Genealogy of Sayyed Muhammad Talha Qasmi DB
            </button>
          </div>
          {tabs ? (
            <div className="arrordians">
              {shajrah.map((item, index) => {
                return (
                  <Accordian
                    title={item.title}
                    content={item.content}
                    id={index + 1}
                    shajrah={true}
                  />
                );
              })}
            </div>
          ) : (
            <div className="arrordians">
              {shajrahData.map((item, index) => {
                return (
                  <div className="list" key={`data_${index}`}>
                    {item?.title}
                  </div>
                );
              })}
              <div className="list notes">
                Note: This genealogy of My grandfather, Janab Abdul Hameed
                Sahab, which is traced back to Sayyiduna Husain bin Ali (May
                Allah Be Pleased with Him) is Taken From the Book 'Arabi-un-Nasl
                Musalmaano Ki Deeni wa Ilmi Khidmaat, written by Abul Qasim
                Abdus Salaam Husaini, Haswi', Published by Majlis Isha'ate
                Uloome Islamiah, Karachi, in 1984.
              </div>
            </div>
          )}
          <Footer />
        </div>
      </main>
    </>
  );
};

export default Shajrah;
