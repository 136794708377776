export const shajrah = [
  {
    title:
      "The Mercy for the Worlds Muhammad Rasulullah (ﷺ‎) [Holy City of Madinah Munawwarah]",
    content: `Muhammad (Arabic: محمد‎; c. 570 – 8 June 632), full name Abū al-Qāsim Muḥammad ibn ʿAbd Allāh ibn ʿAbd al-Muṭṭalib ibn Hāshim (ابو القاسم محمد ابن عبد الله ابن عبد المطلب ابن هاشم), from Mecca, unified Arabia into a single religious polity under Islam. Believed by Muslims and Bahá’ís to be a prophet and messenger of God, Muhammad is almost universally considered by Muslims as the last prophet sent by God to mankind. While non-Muslims generally regard Muhammad as the founder of Islam,[3] Muslims consider him to have restored the unaltered original monotheistic faith of Adam, Abraham, Moses, Jesus, and other prophets in Islam.
    Born approximately in 570 CE in the Arabian city of Mecca, Muhammad was orphaned at an early age; he was raised under the care of his paternal uncle Abu Talib. After his childhood Muhammad primarily worked as a merchant. Occasionally he would retreat to a cave in the mountains for several nights of seclusion and prayer; later, at age 40, he reported at this spot, that he was visited by Gabriel and received his first revelation from God. Three years after this event Muhammad started preaching these revelations publicly, proclaiming that “God is One”, that complete “surrender” (lit. islām) to Him is the only way (dīn) acceptable to God, and that he was a prophet and messenger of God, similar to other Islamic prophets.`,
  },
  {
    title:
      "Hazrat Sayyedina Abu Bakr Siddiq (رضي الله عنه) [Holy City of Madinah Munawwarah]",
    content: `The next link in the chain, Salman al-Farsi﷛ came from an affluent Persian family. He was a seeker of truth who himself narrated that he was sold as a slave by one master to another for more than ten times. [Bukhari 3946] He had the honour to be released from his master by the Beloved Prophet صلى الله عليه وسلم and he remained amongst the Companions of the Suffa. The Beloved Prophet صلى الله عليه وسلم said that Salman (رضی اللہ عنہ) was from Ahle Bayt meaning family of the Prophet صلى الله عليه وسلم. It is narrated by Ibne Jawzi (رحمۃ اللہ علیہ) in his book ‘Sifatus Safwah’ that Salman (رضی اللہ عنہ) said, “When Allah’s slave prays unto Him when times and conditions are good, then the angels recognise his voice when he prays at the time of difficulty and intercede for him. When he does not pray unto Him when times and conditions are good, then the angels do not recognise his voice, when he prays at the time of difficulty and do not intercede for him.” Salman (رضی اللہ عنہ) said to Hudhaifa (رضی اللہ عنہ), “O brother of Banu Abs, there is no limit to knowledge but age is very limited. So, strive to acquire that much knowledge required to enact your religious dealings, and leave what is besides that.” Salman Farsi (رضی اللہ عنہ) passed away during the caliphate of Uthman (رضی اللہ عنہ) in the city of Madain in the 36th year after migration.`,
  },
  {
    title: "Hazrat Sayyedina Salman Farsi (رضي الله عنه) [Madain]",
    content: `The next link in the chain, Salman al-Farsi﷛ came from an affluent Persian family. He was a seeker of truth who himself narrated that he was sold as a slave by one master to another for more than ten times. [Bukhari 3946] He had the honour to be released from his master by the Beloved Prophet صلى الله عليه وسلم and he remained amongst the Companions of the Suffa. The Beloved Prophet صلى الله عليه وسلم said that Salman (رضی اللہ عنہ) was from Ahle Bayt meaning family of the Prophet صلى الله عليه وسلم. It is narrated by Ibne Jawzi (رحمۃ اللہ علیہ) in his book ‘Sifatus Safwah’ that Salman (رضی اللہ عنہ) said, “When Allah’s slave prays unto Him when times and conditions are good, then the angels recognise his voice when he prays at the time of difficulty and intercede for him. When he does not pray unto Him when times and conditions are good, then the angels do not recognise his voice, when he prays at the time of difficulty and do not intercede for him.” Salman (رضی اللہ عنہ) said to Hudhaifa (رضی اللہ عنہ), “O brother of Banu Abs, there is no limit to knowledge but age is very limited. So, strive to acquire that much knowledge required to enact your religious dealings, and leave what is besides that.” Salman Farsi (رضی اللہ عنہ) passed away during the caliphate of Uthman (رضی اللہ عنہ) in the city of Madain in the 36th year after migration.`,
  },
  {
    title:
      "Hazrat Sayyedina Imam Qasim (رحمة الله عليه) [Holy City of Madinah Munawwarah]",
    content: `The next in chain is Imam Qasim (رحمۃ اللہ علیہ) who was Abu Bakr’s (رضی اللہ عنہ) grandson and a Tábayee, known for his piety and knowledge of Deen. His personality was very similar to Sayeddina Abu Bakr (رضی اللہ عنہ). His father Muhammad bin Abu Bakr (رضی اللہ عنہ) was martyred in a battle, so Ummul Momineen Ayesha (رضی اللہ عنہا) took him in his care and he was raised by her. He learnt Qurán and Hadith from many Companions of the Beloved Prophet صلى الله عليه وسلم, therefore considered as one of the greatest scholars of Qurán and Hadith. He also specialised in Islamic Jurisprudence and was regarded as one of the seven great Jurists of Madinah.`,
  },
  {
    title:
      "Hazrat Sayyedina Imam Jafar Sadiq (رحمة الله عليه) [Holy City of Madinah Munawwarah]",
    content: `Imam Qasim (رحمۃ اللہ علیہ) was followed by Imam Jáfar as-Sadiq (رحمۃ اللہ علیہ), who was a great Hadith scholar. Imam Malik (رحمۃ اللہ علیہ) and Imam Abu Hanifa (رحمۃ اللہ علیہ) had narrated Hadith from him. He was born in the 80th year after migration, in the city of Madinah and passed away in the year 148 Hijri. It was as if knowledge was the rightful inheritance of Imam Jáfar aṣ-Ṣadiq (رحمۃ اللہ علیہ). As for his other virtues, he was the Imam of his era and one of the foremost leaders. Even amongst the noble family of the Prophet صلى الله عليه وسلم, his knowledge was unsurpassed. Ibn Ḥibban (رحمۃ اللہ علیہ) stated that he was amongst the leaders in Fiqh and virtuous amongst the family of the Beloved Prophet .صلى الله عليه وسلم Imam Nawawi (رحمۃ اللہ علیہ) said that all agree upon his leadership and august personality. He said, “When Allah grants you a bounty and you wish to retain it, then thank Allah abundantly for it, for Allah says in His Book, ‘If you are grateful, I shall certainly increase for you.’ When there is a delay in receiving sustenance, then seek forgiveness. He who remains content with the share, fate has allotted him, will remain independent. He who gazes at the wealth of others will die poor. He who is not pleased with Allah’s allocation has doubt in that decision of Allah. If one exposes others, Allah will expose him in the privacy of his own home. The one who digs a hole for his brother, will fall in it himself. The one who sits in the company of foolish becomes worthless and the one who sits in the company of scholars becomes honoured. He who goes to places of ill repute gains ill repute.”`,
  },
  {
    title: "Hazrat Khuwaja Bayazid Bastami (رحمة الله عليه) [Bastam]",
    content: `Sultan al-Arifeen, Bayazid al-Bastami (رحمۃ اللہ علیہ), born in 136 Hijri and lived to the age of 73. He was completely annihilated in the Divine Love. His esteemed mother narrated that when she was pregnant with him, whenever she ate a doubtful morsel, she felt so ill that she would feel no peace until she vomited. He used to say that the company of the pious is better than pious deeds and the company of the evil is worse than the evil deeds. Somebody told him about a saint residing in a certain place. When he arrived there, the saint spat in the direction of the Qiblah, Bayazid (رحمۃ اللہ علیہ) remarked, “If that man had the slightest inkling of Ṭariqa, he would not have perpetrated such a disrespectful act.” He was asked as to when one reaches the border of being a true man. He replied, “When one recognises the defects of his ego, he reaches the border of true men.”`,
  },
  {
    title: "Hazrat Khuwaja Abul Hasan Kharqani (رحمة الله عليه) [Kharqan]",
    content: `Shaykh Abu al-Hassan al-Kharqani (رحمۃ اللہ علیہ) was known for his love of Sunnah of the Beloved Prophet صلى الله عليه وسلم. One day he asked
    his followers, “What is the most excellent thing?” “O Shaykh,” they replied, “You tell us.” He said, “That heart in which there is only His remembrance.
    
    ” Some people asked him, “Who is a Sufi” He replied, “A robe, turban or prayer-mat does not make one a Sufi, nor do rituals and tradition make a Sufi. Sufi is the one who considers himself as nothing.”`,
  },
  {
    title: "Hazrat Khuwaja Abul Qasim Gurgani (رحمة الله عليه) [Gorgan]",
    content: `Shaykh Abi Al Qasim Gurgani (رحمۃ اللہ علیہ) was born in Gurgan, Iran. He studied the religious sciences at an early age. He lived in Baghdad and Makkah and finally settled in Nishapur in the North east of Iran. Shaykh Gurgani (رحمۃ اللہ علیہ) authored a book named ‘Fusul al-Tariqah wa Fusul al-Haqiqah’. Ali Hujwiri (رحمۃ اللہ علیہ) also benefitted from him and mentioned him in his book, Kashful Mahjub.

    One of his famous quotes is mentioned in the book is, “Whenever anyone prefers company of rich rather
    than poor, Allah﷯ afflicts him with spiritual death.”`,
  },
  {
    title: "Hazrat Khuwaja Abu Ali Farmadi (رحمة الله عليه) [Mashad]",
    content: ``,
  },
  {
    title: "Hazrat Khawaja Yusuf Hamdani (رحمة الله عليه) [Turkistan]",
    content: `Name and Lineage
    His name was Yousuf, his father’s name was Ayoub and his Kuniyah (appellation) was Abu Yaqoob.
    
    Birth Place
    He was born 440 years after Hijrat in BUZANJARD, a town of Hamdan, an association that resulted in him being referred to as Hamdani.
    
    His Vistues and Excellence
    He was one of the greatest luminaries among the Scholars (Mashaikh) of his age, attending the gatherings of Ulema, Sulaha and Fuqaha, renowned in their knowledge of Fatawa and Ahkam-e-Sharia. It is reported that he was the GHAUS of his time. (GHAUS being the title conferred upon Muslim saints who spend most of their time engaged in the worship of Allah, and it is commonly held that they are so wholly engrossed in worship that their bodies and limbs fall asunder.) He taught in Iraq and Khurasan and helped build the character of the nation. In Khurasan, he was a leading figure in the fields of Tarbiah and Sulook. He spent the sixty years that he was alive in preaching and guiding mankind and received great honour and respect and became one of the most famous personalities of his era.
    
    Academic Knowledge
    He acquired his knowledge in Fiqah from Abu Ishaq Sheerazi and studied Hadithunder the tutelage of QaziAbul Husain Muhammad Bin Ali Abul Ghanaem Abdus Samad, Abu Jafar Muhammad bin Ahmed. He travelled far and wide to quench his thirst for knowledge, a thirst which had begun to manifest itself at a very youthful age, thereby resulting in him travelling to the cities of Baghdad, Asfahan, Iraq, Khurasan, Samarqand and Bukhara (well known cities of knowledge) when he was but eighteen and seeking knowledge from the renowned scholars residing there.
    
    Suhbat: (keeping good campany with)
    He was trained in good company of Shaikh Abdullah Jowainee Neshapooree and Shaikh Hasan Samnani. It is known that he was getting relation in Tusawwuf to Shaikh Abu Ali Farmadi.
    
    Death:
    While travelling from Harat to Marw, he passed away on the 22nd of Rabiulawwal 535 years after Hijrat at Bamaeen and was buried there. After the elapse of some time his body was transferred by one of his pupils, Ibn-u-Najar, to Murw and buried there. His burial site has been named after him and people come from far and sundry to visit his Shrine to get Faiz (bounty of Allah subhanahu Taala).`,
  },
  {
    title: "Hazrat Khuwaja Abdul Khaliq Ghijduwani (رحمة الله عليه) [Bukhara]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Muhammad Arif Riwgari (رحمة الله عليه) [Tajikistan]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Mehmood Anjir Faghnavi (رحمة الله عليه) [Bukhara]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Azizan Ali Ramaitani (رحمة الله عليه) [Bukhara]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Muhammad Baba Samasi (رحمة الله عليه) [Bukhara]",
    content: ``,
  },
  {
    title: "Hazrat Khawaja Syed Ameer Kulaal (رحمة الله عليه) [Bukhara]",
    content: `Birth and Lineage
    His date of birth could not be known. He belonged to an extremely respected family. He was born in Mozad Sokhar near Bukhara. He is known as Kalaal because he used to do pottery which is known as Kalaal in the language of Bukhara.
    
    Initial Conditions
    In youth, he was a wrestler and he used to spend his time in matches. One day, Baba Samaasi happened to pass by the wrestling area, and since then Ameer Kalaal began to change. When he saw Babas face, this wrestler received the gift of spiritual enlightenment. Baba accepted him as his son and Kalaal received the gift of spirituality from him.
    
    Bait and Khilafat
    He was with Baba Samaas for twenty years. He used to visit Samaas twice a week, that is, on Mondays and Thursdays. He became the Khalifa after Baba, and he was among Baba’s great pupil.
    
    Death
    He passed away on the 8th of Gamadi-ul- Awal, 772 Hijri at the time of Fajr. His shrine is in Sukhaar.`,
  },
  {
    title:
      "Hazrat Khuwaja Bahauddin Naqshband Bukhari (رحمة الله عليه) [Bukhara]",
    content: `Birth
    He was born on the 4th of Muharram, 718 Hijri in Kasr-e- Arfa which is one Farsang away from Bukhara.
    
    Upbringing and Company
    On the third day of his birth, his grandfather took him to Baba Samaas. Baba Samaas accepted him as his son, and asked Ameer Kulaal to promise him to rear Khawaja Sahab. In this way, he entered the fortunate group of Ameer Kulaal and gradually became one of Ameers great Khalifas. On his death bed, Ameer directed all his Khalifas to be followers of Khawaja Sahab.
    
    Zuhd-o-Taqwa
    In his childhood, signs of Wilayat were evident in him. In fact, before his birth, Baba Samaas predicted that he will have such a noble character. He was simple and preached simplicity. He says that all that he has gained is due to simplicity. He was very particular about eating lawful food. Hazrat Mujadad-ul- Afsani says about him: He was a model of Fana-Fi-Allah and Baqa-Fi Allah.
    
    Reasons for his name Naqshbandi
    When anybody met him for the first time, even in the first meeting, he made a powerful impression on people which erased all their previous thoughts. For this reason, he is called Naqshbandi. People used to become pious in his company.
    
    The Strange Incident of his Death
    He used to tell his followers that he will teach them how to die when he will die. Consequently, when he was dying, he raised both his hands to pray and kept praying for a long time. By the time he massaged his hands on his face, he had already passed away. He passed away on the 3rd of Rabbiual Awal, 791 Hijri at the age of 73. His shrine is in Kasr-e-Arfa.`,
  },

  {
    title: "Hazrat Khuwaja Ala’uddin Attar (رحمة الله عليه) [Hassar]",
    content: ``,
  },
  {
    title: "Hazrat Khawaja Yaqoob Charkhi (رحمة الله عليه) [Dushanbe]",
    content: ``,
  },
  {
    title: "Hazrat Khawaja Ubaidullah Ahrar (رحمة الله عليه) [Samarqand]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Maulana Muhammad Zahid (رحمة الله عليه) [Hassar]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Darvesh Muhammad (رحمة الله عليه) [Sher Sabz]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Muhammad Amkanagi (رحمة الله عليه) [Bukhara]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Muhammad Baqi Billah (رحمة الله عليه) [Delhi]",
    content: ``,
  },
  {
    title:
      "Hazrat Khuwaja Mujaddid Alf-Thani (رحمة الله عليه) [Sirhind Sharif]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Muhammad Masoom (رحمة الله عليه) [Sirhind Sharif]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Saifuddin (رحمة الله عليه) [Sirhind Sharif]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Muhammad Mohsin Dehlvi (رحمة الله عليه) [Delhi]",
    content: ``,
  },
  {
    title:
      "Hazrat Khuwaja Syed Noor Muhammad Badayuni (رحمة الله عليه) [Delhi]",
    content: ``,
  },
  {
    title: "Hazrat Mirza Mazhar Jan-e-jana (رحمة الله عليه) [Delhi]",
    content: ``,
  },
  {
    title: "Hazrat Shah Ghulam Ali Dehlavi (رحمة الله عليه) [Delhi]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Shah Abu Saeed (رحمة الله عليه) [Delhi]",
    content: ``,
  },
  {
    title:
      "Hazrat Khuwaja Shah Ahmed Saeed Madni (رحمة الله عليه) [Holy City of Madinah Munawwarah]",
    content: ``,
  },
  {
    title:
      "Hazrat Haji Dost Muhammad Qandhari (رحمة الله عليه) [Musa Zai Shareef]",
    content: ``,
  },
  {
    title:
      "Hazrat Khuwaja Muhammad Usman Damani (رحمة الله عليه) [Musa Zai Sharif]",
    content: ``,
  },
  {
    title: "Hazrat Khuwaja Sirajuddin (رحمة الله عليه) [Musa Zai Sharif].",
    content: ``,
  },
  {
    title:
      "Hazrat Khuwaja Muhammad Fazal Ali Qureshi (رحمة الله عليه) [Miskeenpur Sharif]",
    content: `His disciple, Shaykh Muhammad Fazal Ali Qureshi (رحمۃ اللہ علیہ) was born in Mianwali and later established the town of Miskeenpur. He was also a Hadith scholar and used to earn his living by farming. He used to perform Dhikr of the Divine Supreme Name ‘Allah’ while tilling the land. It is said, that he used to serve the plain bread with curd milk produced from his farmlands to the seekers. Due to constant Dhikr, the food had immense blessings and the hearts of the seekers were illuminated
    in days. He used to stay in the hometown for six months and travelled the rest of the year. He was extremely modest; he never took a ride to Musa Zai Shareef and used to walk for two hundred and fifty kilometres to visit his Shaykh’s hometown.
    Once, Syed Ataullah Shah Bukhari (رحمۃ اللہ علیہ), Ameer of ‘Tahaffuz e Khatm e Nubuwat’ (Safeguarding the belief of finality of Prophethood of Muhammad صلى الله عليه وسلم) went to see Shaykh Fazal Ali (رحمۃ اللہ علیہ) and found him tilling the fields. Shah Ataullah (رحمۃ اللہ علیہ) requested to pray for him, Shaykh (رحمۃ اللہ علیہ) placed his finger on the heart of Shah Bukhari (رحمۃ اللہ علیہ) and his heart started Dhikr Allah.
    Once, Shah Bukhari (رحمۃ اللہ علیہ) was going to Multan for a special congregation to condemn Qadiyaniat, he requested Shaykh Fazal Ali (رحمۃ اللہ علیہ) to bless him with his company and pray for him. Shaykh Fazal Ali (رحمۃ اللہ علیہ) accompanied him and Shah Ataullah Bukhari (رحمۃ اللہ علیہ) delivered a great speech against Qadiyaniat. When Shaykh Fazal Ali (رحمۃ اللہ علیہ) was about to leave, Shah (رحمۃ اللہ علیہ) requested him to stay and said that the words from him were the spiritual blessing of Shaykh Fazal Ali Qureshi (رحمۃ اللہ علیہ).`,
  },
  {
    title:
      "Hazrat Khuwaja Muhammad Abdul Malik Siddiqui (rahmatullahi alaihi) [Khanewal]",
    content: "",
  },
  {
    title:
      "Murshid-e-Alam Hazrat Khuwaja Ghulam Habib (rahmatullahi alaihi) [Chakwal].",
    content: "",
  },
  {
    title:
      "Hazrat Maulana Hafiz Zulfiqar Ahmad Naqshbandhi Mujaddidi (DB) [Jhang Sharif].",
    content: `Hazrat Zulfiqar Ahmed (db) stays desirous of meeting his beloved. Grant us Your Union for the sake of all Auliya. In 2011, Zulfiqar Ahmad Naqshbandi travelled to India and addressed in few organized programs at Eidgah Bilali Mansab tank and Chanchalguda Junior College in Hyderabad. He also spoke at programs in the Masjid Rasheed of Darul Uloom Deoband and at Darul Uloom Waqf, Deoband.
    Mr. Zulfiqar Ahmad Naqshbandi was among the world's 500 most influential Muslims during 2013 / 14.
    Even though Sufi intellectual output has fallen in modern times, Naqshbandi remains an exception and has published widely`,
  },
  {
    title:
      "Hazrat Maulana sayyed muhammed Talha Qasmi Naqshbandi (DB)[Bhiwandi]",
    content: `Hazrat Shaykh Sayyid Muhammad Talha Qasmi Naqshbandi Sahab (DB) is the blessed progeny of the Prophet Muhammad ( صلى الله عليه وسلم) of Hazrat Hussain Ibn Ali Raziallahuanhu’s descent. Hazrat (DB) graduated from Darul-Uloom Deoband in the year 1983 with grades that were unheard in the last decade almost.

    Hazrat (DB) was identified and trained (had Tarbiyat) under the blessed guidance of Hazrat-e-Aqdas Maulana Qari Siddiq Sab Bandhawi (RAH) until Shaykh Siddiq (RAH)’s departure to the afterlife.`,
  },
];

export const accordionData = [
  {
    title: "Section 1",
    content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`,
  },
  {
    title: "Section 2",
    content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
      reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
      quaerat iure quos dolorum accusantium ducimus in illum vero commodi
      pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
      quidem maiores doloremque est numquam praesentium eos voluptatem amet!
      Repudiandae, mollitia id reprehenderit a ab odit!`,
  },
  {
    title: "Section 3",
    content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
      quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
      dolor ut sequi minus iste? Quas?`,
  },
];
