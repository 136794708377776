import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({ setPage, limit, pageNumbers, setLimit, page }) => {

  return (
    <div className="pagination-wrapper">
      <Link
        className={limit.start !== 1 ? "active" : " disablecls"}
        to="#"
        onClick={
          limit.start !== 1
            ? () => {
              setPage(limit.start - 3);
              setLimit({
                start: limit.start - 3,
                end: limit.start - 1,
              });
            }
            : () => { }
        }
      >
        <i className="fas fa-angle-left"></i>
      </Link>
      {pageNumbers?.map((num, i) => {
        if (limit.start <= i + 1 && limit.end >= i + 1) {
          return (
            <Link
              to="#"
              className={page === i + 1 ? "active" : "paginationnumbersbg"}
              onClick={() => setPage(i + 1)}
              key={i}
            >
              {num}
            </Link>
          );
        }
      })}
      <Link
        className={limit.end < pageNumbers.length ? " active" : "disablecls"}
        onClick={
          limit.end < pageNumbers.length
            ? () => {
              setPage(limit.end + 1);
              setLimit({
                start: limit.end + 1,
                end: limit.end + 3,
              });
            }
            : () => { }
        }
        to="#"
      >
        <i className="fas fa-angle-right"></i>
      </Link>
    </div>
  );
};

export default Pagination;
