import React from "react";
import Logo from "../../../assets/images/logo.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="img-wrapper">
        <img
          src={Logo}
          alt="logo"
        />
      </div>
      <div className="social-media-link-wrapper">
        <a href="http://">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="http://">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="http://">
          <i className="fab fa-youtube"></i>
        </a>
        <a href="http://">
          <i className="fab fa-instagram"></i>
        </a>
      </div>
      <h2 className="footer-last-title">
        <b>© 2022</b>, tariqat.org
      </h2>
    </div>
  );
};

export default Footer;
