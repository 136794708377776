import React, { useEffect, useState } from "react";
import DashboardLayout from "../dashboardLayout";
import "./floor-plan.css";
import { addFloorPlanApi, getFloorPlanListApi } from "../../../api/adminApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, TextField, Select } from "@mui/material";
import { ReactComponent as SeatIcon } from "../../../assets/images/floor/mosqueIcon.svg";
import SelectInput from "../../../components/common/SelectInput";
import moment from "moment";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { createFloorValidateField } from "../../../helpers/validateField";

function Seat(props) {
  const availability = props.available ? "available" : "booked";
  //className={`seat ${availability}`}
  return (
    <div className="seat-icon create">
      <SeatIcon />
      <p className="seat-icon-info">{props?.name}</p>
    </div>
  );
  // return <div className={`seat ${availability}`}>{props?.name}</div>;
}

function Floor(props) {
  const { floorName, rows, onModifyRow } = props;

  function addRow() {
    const newRow = {
      rowName: String.fromCharCode(65 + rows.length),
      seats: Array.from(
        { length: rows[rows.length - 1].seats.length },
        (_, index) => ({
          number: index + 1,
          registeredUser: null,
        })
      ),
    };
    onModifyRow([...rows, newRow]);
  }

  function handleDeleteColumn(rowIndex, columnIndex) {
    const updatedRows = [...rows];
    updatedRows[rowIndex].seats.splice(columnIndex, 1);
    onModifyRow(updatedRows);
  }

  function handleModifyRow(rowIndex, modifiedRow) {
    onModifyRow(modifiedRow);
  }

  return (
    <div className="floor">
      <h2>{floorName}</h2>
      <div className="floor-wrap">
        {rows.map((row, rowIndex) => (
          <Row
            key={row.name}
            row={row}
            rowIndex={rowIndex}
            onDeleteColumn={(columnIndex) =>
              handleDeleteColumn(rowIndex, columnIndex)
            }
            onModifyRow={(modifiedRow) =>
              handleModifyRow(rowIndex, modifiedRow)
            }
          />
        ))}
      </div>
      <Button variant="outlined" onClick={addRow}>
        Add Row
      </Button>
    </div>
  );
}

function Row(props) {
  const { row, rowIndex, onDeleteColumn, onModifyRow } = props;

  function toggleSeatAvailability(index) {
    const modifiedRow = { ...row };
    modifiedRow.seats[index].registeredUser = !row.seats[index].registeredUser;
    onModifyRow(modifiedRow);
  }

  function handleDeleteColumn(index) {
    onDeleteColumn(index);
  }

  return (
    <div className="seat-view-wrapper">
      <div className="wrap">
        {row.seats.map((seat, index) => (
          <Seat
            key={index}
            name={`${row.rowName}${index + 1}`}
            available={!seat.registeredUser}
            onClick={() => toggleSeatAvailability(index)}
          />
        ))}
      </div>
      <Button
        variant="contained"
        color="error"
        className="delete-seat"
        onClick={() => handleDeleteColumn(row, rowIndex)}
      >
        X
      </Button>
    </div>
  );
}

function CreateFloorPlan() {
  const InitialState = {
    masjidName: "",
    cityName: "",
    floorName: "",
    rowCount: 3,
    columnCount: 3,
    regOpen: false,
    // reminderCount: 0,
    regStartDateTime: "",
    regEndDateTime: "",
    // remCountDateTime: "",
    confirmationDateTime: "",
    ithkaafDateTime: "",
    // isregNotified: "",
    // isConfirmationRem: "",
  };

  const [formData, setFormData] = useState(InitialState);
  const [errors, setErrors] = useState({});
  const [floorErr, setFloorErr] = useState({});
  const [floorData, setFloorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    masjidName,
    cityName,
    floorName,
    rowCount,
    columnCount,
    regOpen,
    // reminderCount,
    regStartDateTime,
    regEndDateTime,
    // remCountDateTime,
    confirmationDateTime,
    ithkaafDateTime,
    // isregNotified,
    // isConfirmationRem,
  } = formData;

  //   const [listSize, setListsize] = useState(1);
  //   const [pageNumbers, setPageNumbers] = useState([]);
  //   const [limit, setLimit] = useState({ start: 1, end: 3 });
  //   const [page, setPage] = useState(1);
  //   const SIZE = 5;

  //   useEffect(() => {
  //     let value = parseInt(listSize / SIZE) + (listSize % SIZE > 0 ? 1 : 0);
  //     let pageNumbers = [];
  //     for (let i = 1; i <= value; i++) {
  //       pageNumbers.push(i);
  //     }
  //     setPageNumbers(pageNumbers);
  //   }, [listSize]);

  //   const getFloorPlanList = () => {
  //     dispatch(getFloorPlanListApi("", SIZE, setListsize, page, setLoading));
  //   };
  //   useEffect(() => {
  //     getFloorPlanList();
  //   }, [page]);

  const ErrorMessage = ({ name }) => {
    return errors[name] && <p className="error-field">{errors[name]}</p>;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const fieldError = createFloorValidateField(name, value);

    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "rowCount" || name === "columnCount"
          ? parseInt(value, 10)
          : value,
    }));

    if (fieldError) {
      setErrors({
        [name]: fieldError,
      });
    } else {
      setErrors({});
    }
  };

  // function handleModifyRow(floorIndex, modifiedRow) {
  //   setFloorData((prevFloorData) => {
  //     const updatedFloors = [...prevFloorData.floors];
  //     updatedFloors[floorIndex].rows = modifiedRow;
  //     return {
  //       ...prevFloorData,
  //       floors: updatedFloors,
  //     };
  //   });
  // }

  function handleModifyRow(floorIndex, modifiedRow) {
    setFloorData((prevFloorData) => {
      const updatedFloors = [...prevFloorData.floors];
      updatedFloors[floorIndex].rows = modifiedRow;

      // Calculate the updated capacity based on the modifiedRow
      const newCapacity = modifiedRow.reduce(
        (totalSeats, row) => totalSeats + row.seats.length,
        0
      );

      // Update the capacity in the floorData
      updatedFloors[floorIndex].capacity = newCapacity;

      return {
        ...prevFloorData,
        floors: updatedFloors,
      };
    });
  }

  function generateFloor() {
    if (formData?.floorName) {
      setFloorErr((prevData) => ({
        ...prevData,
        ["floorName"]: "",
      }));
      const newFloor = {
        floorName,
        capacity: rowCount * columnCount,
        rows: Array.from({ length: rowCount }, (_, i) => ({
          rowName: formData?.floorName?.charAt(0) + getRowName(i),
          seats: Array.from({ length: columnCount }, (_, j) => ({
            number: j + 1,
            registeredUser: null,
          })),
        })),
      };

      // Update capacity based on the number of seats
      newFloor.capacity = newFloor.rows.reduce(
        (totalSeats, row) => totalSeats + row.seats.length,
        0
      );

      function getRowName(index) {
        const baseChar = 65; // ASCII code for 'A'
        let rowName = "";

        while (index >= 0) {
          const charCode = baseChar + (index % 26);
          rowName = String.fromCharCode(charCode) + rowName;
          index = Math.floor(index / 26) - 1;
        }

        return rowName;
      }

      setFloorData((prevFloorData) => ({
        name: masjidName,
        city: cityName,
        floors: [...(prevFloorData?.floors || []), newFloor],
        registrationOpen: regOpen,
        registrationStart: moment(regStartDateTime).format(
          "YYYY-MM-DDTHH:mm:ssZ"
        ),
        registrationEnd: moment(regEndDateTime).format("YYYY-MM-DDTHH:mm:ssZ"),
        // remainderCountTime: moment(remCountDateTime).format(
        //   "YYYY-MM-DDTHH:mm:ssZ"
        // ),
        confirmationDate: moment(confirmationDateTime).format(
          "YYYY-MM-DDTHH:mm:ssZ"
        ),
        ithikaafDate: moment(ithkaafDateTime).format("YYYY-MM-DDTHH:mm:ssZ"),
        // remainderCount: parseInt(reminderCount),
        year: `${moment().year()}`,
        // isRegNotified: isregNotified,
        // isConfirmationReminderNotified: isConfirmationRem,
      }));

      setFormData((prevData) => ({
        ...prevData,
        floorName: "",
        rowCount: 3,
        columnCount: 3,
      }));
    } else {
      setFloorErr((prevData) => ({
        ...prevData,
        ["floorName"]: "Floor Name is required",
      }));
    }
  }

  function handleSubmit() {
    const formErrors = {};
    Object.keys(formData).forEach((name) => {
      const value = formData[name];
      const fieldError = createFloorValidateField(name, value);
      if (fieldError) {
        formErrors[name] = fieldError;
      }
    });

    setErrors(formErrors);

    if (floorData?.floors?.length > 0) {
      setFloorErr({});
    } else {
      setFloorErr((prevData) => ({
        ...prevData,
        ["floorData"]: "Floor is required",
      }));
    }
    if (
      Object.keys(formErrors).length === 0 &&
      Object.keys(floorErr).length === 0
    ) {
      dispatch(addFloorPlanApi(floorData, setLoading, navigate));
    }
  }

  return (
    <DashboardLayout>
      <div className="floor-map">
        <div className="controls">
          <div className="back-btn-wrap">
            <Button
              variant="contained"
              className="back-btn"
              onClick={() => navigate(-1)}
            >
              <i class="fa-solid fa-arrow-left"></i> Back
            </Button>
          </div>
          <h1>Masjid Details</h1>
          <br />
          <div className="form-group-control">
            <div className="form-group w50">
              <TextField
                type="text"
                variant="outlined"
                label="Masjid Name:"
                name="masjidName"
                value={formData?.masjidName}
                onChange={handleChange}
              />
              {errors?.masjidName && (
                <p className="error-msg">
                  <ErrorMessage name="masjidName" />
                </p>
              )}
            </div>
            <div className="form-group w50">
              <TextField
                type="text"
                variant="outlined"
                label="City Name:"
                name="cityName"
                value={formData?.cityName}
                onChange={handleChange}
              />
              {errors?.cityName && (
                <p className="error-msg">
                  <ErrorMessage name="cityName" />
                </p>
              )}
            </div>
          </div>
          <hr
            style={{
              width: "100%",
              backgroundColor: "black",
              fontWeight: "bold",
            }}
          />
          <h1>Masjid Etikaaf Information Details</h1>
          <br />
          <div className="form-group-control fields">
            <div className="form-group w50 select">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Regestation Open
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData?.regOpen}
                  name="regOpen"
                  label="Regestration Open"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
              {/* {errors?.regOpen && (
                <p className="error-msg">
                  <ErrorMessage name="regOpen" />
                </p>
              )} */}
            </div>
            {/* <div className="form-group w50">
              <TextField
                type="number"
                variant="outlined"
                label="Reminder Count:"
                name="reminderCount"
                value={formData.reminderCount}
                onChange={handleChange}
              />
            </div> */}
            <div className="form-group w50">
              <TextField
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                variant="outlined"
                label="Regestration Start Date and Time:"
                name="regStartDateTime"
                value={formData?.regStartDateTime}
                onChange={handleChange}
              />
              {errors?.regStartDateTime && (
                <p className="error-msg">
                  <ErrorMessage name="regStartDateTime" />
                </p>
              )}
            </div>
            <div className="form-group w50">
              <TextField
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                variant="outlined"
                label="Regestration End Date and Time:"
                name="regEndDateTime"
                value={formData?.regEndDateTime}
                onChange={handleChange}
              />
              {errors?.regEndDateTime && (
                <p className="error-msg">
                  <ErrorMessage name="regEndDateTime" />
                </p>
              )}
            </div>
            {/* <div className="form-group w50">
              <TextField
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                variant="outlined"
                label="Reminder Count Date and Time:"
                name="remCountDateTime"
                value={formData.remCountDateTime}
                onChange={handleChange}
              />
            </div> */}
            <div className="form-group w50">
              <TextField
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                variant="outlined"
                label="Confirmation Date and Time:"
                name="confirmationDateTime"
                value={formData?.confirmationDateTime}
                onChange={handleChange}
              />
              {errors?.confirmationDateTime && (
                <p className="error-msg">
                  <ErrorMessage name="confirmationDateTime" />
                </p>
              )}
            </div>
            <div className="form-group w50">
              <TextField
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                variant="outlined"
                label="Ithikaaf Date and Time:"
                name="ithkaafDateTime"
                value={formData?.ithkaafDateTime}
                onChange={handleChange}
              />
              {errors?.ithkaafDateTime && (
                <p className="error-msg">
                  <ErrorMessage name="ithkaafDateTime" />
                </p>
              )}
            </div>
            {/* <div className="form-group w50 select">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Is Reg Notified
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.isregNotified}
                  label="Is Reg Notified"
                  name="isregNotified"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </div> */}
            {/* <div className="form-group w50 select">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Is Confirmation Reminder Notified
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.isConfirmationRem}
                  name="isConfirmationRem"
                  label="Is Confirmation Reminder Notified"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </div> */}
            <hr
              style={{
                width: "100%",
                backgroundColor: "black",
                fontWeight: "bold",
              }}
            />
            <h1>Masjid Floor Info</h1>
            <br />
            <div className="form-group w100">
              <TextField
                variant="outlined"
                type="text"
                label="Floor Name:"
                name="floorName"
                value={formData?.floorName}
                onChange={handleChange}
              />
              {floorErr?.floorName && (
                <p className="error-msg">{floorErr?.floorName}</p>
              )}
            </div>
            <div className="form-group w50">
              <TextField
                type="number"
                variant="outlined"
                label="Number of Rows:"
                name="rowCount"
                value={formData?.rowCount}
                onChange={handleChange}
              />
              {errors?.rowCount && (
                <p className="error-msg">
                  <ErrorMessage name="rowCount" />
                </p>
              )}
            </div>

            <div className="form-group w50">
              <TextField
                type="number"
                variant="outlined"
                label="Number of Columns:"
                name="columnCount"
                value={formData?.columnCount}
                onChange={handleChange}
              />
              {errors?.columnCount && (
                <p className="error-msg">
                  <ErrorMessage name="columnCount" />
                </p>
              )}
            </div>
          </div>
          <Button
            variant="outlined"
            className="generate-floor"
            onClick={generateFloor}
          >
            Add Floor
          </Button>
          {floorErr?.floorData && (
            <p className="error-msg floor-msg">{floorErr?.floorData}</p>
          )}
        </div>
        {floorData?.floors?.map((floor, index) => (
          <Floor
            key={index}
            floorName={floor.floorName}
            rows={floor.rows}
            onModifyRow={(modifiedRow) => handleModifyRow(index, modifiedRow)}
          />
        ))}

        <div className="submit-btn-wrapper">
          <Button variant="contained" onClick={handleSubmit}>
            Submit Changes
          </Button>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CreateFloorPlan;
