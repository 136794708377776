import React, { useEffect, useState } from "react";
import "../user-list/style.css";
import "../user-list/user-list.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addLiveBroadcastApi,
  deleteFloorPlanListApi,
  deleteSalaqeenApi,
  getFloorPlanListApi,
  getSalaqeenListApi,
  updateSalaqeenApi,
} from "../../../api/adminApi";
import { adminSelector } from "../../../redux/slicers/adminSlice";
import DashboardLayout from "../dashboardLayout";
import { errorValidator } from "../../../helpers/projectHelper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import validateField from "./../../../helpers/validateField";
import { PulseLoader } from "react-spinners";
import Pagination from "../../../components/common/Pagination";
import { CREATE_FLOOR_PLAN, FLOOR_PLAN, ITIKAAF_ASSIGNED_SEATS_LIST } from "../../../config/constants/routePathConstants";

const initialState = {
  name: "",
  age: "",
  state: "",
  city: "",
  whatsappNumber: "",
  education: "",
  occupation: "",
  baitStatus: "",
  baitDuration: "",
  email: "",
  isApproved: false,
};

const FloorPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { floorPlanLists = [] } = useSelector(adminSelector);

  const [formValues, setFormValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [focusedInput, setFocusedInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [id, setId] = useState("");

  const [listSize, setListsize] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [limit, setLimit] = useState({ start: 1, end: 3 });
  const [page, setPage] = useState(1);
  const SIZE = 5;

  useEffect(() => {
    let value = parseInt(listSize / SIZE) + (listSize % SIZE > 0 ? 1 : 0);
    let pageNumbers = [];
    for (let i = 1; i <= value; i++) {
      pageNumbers.push(i);
    }
    setPageNumbers(pageNumbers);
  }, [listSize]);

  const getFloorPlanList = () => {
    dispatch(getFloorPlanListApi(SIZE, setListsize, page, setLoading));
  };


  useEffect(() => {
    getFloorPlanList()
  }, [page]);

  const changeHandler = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;
    const fieldError = validateField(name, fieldValue);

    setFormValues({
      ...formValues,
      [name]: fieldValue,
    });

    if (fieldError) {
      setErrors({
        [name]: fieldError,
      });
    } else {
      setErrors({});
    }
  };

  const focusHandler = (event) => {
    setFocusedInput(event.target.name);
  };

  const blurHandler = () => {
    setFocusedInput("");
  };

  const updateForm = (event) => {
    event.preventDefault();
  //   const formErrors = {};

  //   Object.keys(formValues).forEach((name) => {
  //     const value = formValues[name];
  //     const fieldError = validateField(name, value);
  //     if (fieldError) {
  //       formErrors[name] = fieldError;
  //     }
  //   });

  //   setErrors(formErrors);

  //   if (Object.keys(formErrors).length === 0) {
  //     // Submit the form data
  //     setLoadingBtn(true);
  //     dispatch(updateSalaqeenApi(formValues, id, handleUpdClose, setLoadingBtn, getSalaqeenList));
  //   }
  };

  const handleDeleteFloorPlan = () => {
    dispatch(deleteFloorPlanListApi(id, getFloorPlanList));
    setDelOpen(false);
  };

  const clearInputField = () => {
    setFormValues(initialState);
  };
  //open dialog for upload
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    navigate(CREATE_FLOOR_PLAN)
  };

  const handleClose = () => {
    clearInputField();
    setOpen(false);
  };

  //open dialog for update
  const [updOpen, setUpdOpen] = useState(false);

  const handleUpdClickOpen = (item) => {
    setUpdOpen(true);
    setFormValues({
      name: item?.name,
      age: item?.age,
      state: item?.state,
      city: item?.city,
      whatsappNumber: item?.whatsappNumber,
      education: item?.education,
      occupation: item?.occupation,
      baitStatus: item?.baitStatus,
      baitDuration: item?.baitDuration,
      email: item?.email,
      isApproved: item?.isApproved,
    });
  };

  const handleUpdClose = () => {
    clearInputField();
    setUpdOpen(false);
  };

  //open dialog for delete
  const [delOpen, setDelOpen] = useState(false);

  const handleDelClickOpen = () => {
    setDelOpen(true);
  };

  const handleDelClose = () => {
    setDelOpen(false);
  };
  return (
    <DashboardLayout>
      <div className=" video-upload">
        <div className="title-video-upload">
          <h1>I'tikaf Floors & Swapping </h1>
        </div>
        <div className="flex table-lists">
          <div className="flex-large">
            <table class="table" style={{ border: "1px solid #e9ecef" }}>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Masjid Name</th>
                  <th style={{ width: "15%" }}>City</th>
                  <th style={{ width: "30%" }}>Registration Open</th>
                  {/* <th style={{ width: "30%" }}>Action</th> */}
                  {/* <th style={{ width: "25%" }}></th> */}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="loading-tr">
                    <div className="loading-table-wrapper">
                      <PulseLoader color="#0e5472"
                        style={{ display: "flex", justifyContent: "center" }}
                      />
                    </div>
                  </tr>
                ) : floorPlanLists.length > 0 ? (
                  floorPlanLists.map((item) => (
                    <tr key={item?._id}>
                      <td>{item?.name}</td>
                      <td>{item?.city}</td>
                      <td>{item?.registrationOpen  ? "Yes" : "No"}</td>
                      <td>
                        <div className={"button-div"}>
                          <button
                            className="btn edit-btn btn-primary"
                            type="button"
                            class="btn btn-primary"
                            onClick={() => {
                              navigate(`${ITIKAAF_ASSIGNED_SEATS_LIST}/${item?._id}`)
                              // setId(item?._id);
                              // handleClickOpen(item);
                            }}
                          >
                            View
                          </button>
                          {/* <button
                            className="btn edit-btn btn-primary"
                            type="button"
                            class="btn btn-success"
                            onClick={() => {
                              setId(item?._id);
                              handleUpdClickOpen(item);
                            }}
                          >
                            Edit
                          </button> */}
                          {/* <button
                            className="btn delete-btn btn-danger"
                            onClick={() => {
                              setId(item?._id);
                              handleDelClickOpen();
                            }}
                          >
                            Delete
                          </button> */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div>
                    <h1 colSpan={3}>No List</h1>
                  </div>
                )}
              </tbody>
            </table>
            <div className="customer_page">
              <div className="pagination">
                <Pagination
                  setPage={setPage}
                  limit={limit}
                  pageNumbers={pageNumbers}
                  setLimit={setLimit}
                  page={page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //for view */}
      <Dialog
        className="video-upload-dialog"
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"User Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div class="modal-body">
              <table class="table">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Name<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Email<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.email}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Age<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.age}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      State<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.state}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      City<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.city}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Whatsapp Number<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.whatsappNumber}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Education<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.education}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Occupation<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.occupation}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Whom are you bait with?<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.baitStatus}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      How long have you been bait?<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.baitDuration}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>
                      Status<span style={{ float: "right" }}>:</span>
                    </td>
                    <td>{formValues?.isApproved ? "Approved" : "Pending"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button variant="contained" autoFocus onClick={handleClose}>
            CLOSE
          </Button>
          {/* <Button variant="contained" autoFocus onClick={addLiveBroadCast}>
            SUBMIT
          </Button> */}
        </DialogActions>
      </Dialog>

      {/* //for delete  */}
      <Dialog
        className="video-upload-dialog"
        open={delOpen}
        onClose={handleDelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete this Floor Plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button variant="outlined" onClick={handleDelClose}>
            CLOSE
          </Button>
          <Button
            color="error"
            variant="contained"
            autoFocus
            onClick={handleDeleteFloorPlan}
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>

      {/* //for update video */}
      <Dialog
        className="video-upload-dialog"
        fullWidth={true}
        maxWidth={"sm"}
        open={updOpen}
        onClose={handleUpdClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit Video"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-salaqeen">
            <div>
              <div class="form-check form-switch">
                <label
                  class="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                >
                  Status
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  name="isApproved"
                  onFocus={focusHandler}
                  onBlur={blurHandler}
                  onChange={changeHandler}
                  value={formValues?.isApproved}
                  checked={formValues?.isApproved}
                />
              </div>
              <label>Name</label>
              <input
                className={`form-control col-md-12 ${errors?.name ? "is-invalid" : ""
                  }`}
                name="name"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                value={formValues?.name}
                error={errors?.name ? true : false}
              />
              {errors?.name && <p className="error-text">{errors?.name}</p>}

              <label>Age</label>
              <input
                className={`form-control col-md-12 ${errors?.age ? "is-invalid" : ""
                  }`}
                name="age"
                type={"number"}
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                value={formValues?.age}
                error={errors?.age ? true : false}
              />
              {errors?.age && <p className="error-text">{errors?.age}</p>}

              <label>Email</label>
              <input
                type={"email"}
                className={`form-control col-md-12 ${errors?.email ? "is-invalid" : ""
                  }`}
                name="email"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                value={formValues?.email}
                error={errors?.email ? true : false}
              />
              {errors?.email && <p className="error-text">{errors?.email}</p>}

              <label>State</label>
              <input
                className={`form-control col-md-12 ${errors?.state ? "is-invalid" : ""
                  }`}
                type={"text"}
                name="state"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                value={formValues?.state}
                error={errors?.state ? true : false}
              />
              {errors?.state && <p className="error-text">{errors?.state}</p>}

              <label>City</label>
              <input
                className={`form-control col-md-12 ${errors?.city ? "is-invalid" : ""
                  }`}
                name="city"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                value={formValues?.city}
                error={errors?.city ? true : false}
              />
              {errors?.city && <p className="error-text">{errors?.city}</p>}

              <label>Whatsapp Number</label>
              <input
                className={`form-control col-md-12 ${errors?.whatsappNumber ? "is-invalid" : ""
                  }`}
                name="whatsappNumber"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                value={formValues?.whatsappNumber}
                error={errors?.whatsappNumber ? true : false}
              />
              {errors?.whatsappNumber && (
                <p className="error-text">{errors?.whatsappNumber}</p>
              )}

              <label>Education</label>
              <input
                className={`form-control col-md-12 ${errors?.education ? "is-invalid" : ""
                  }`}
                name="education"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                value={formValues?.education}
                error={errors?.education ? true : false}
              />
              {errors?.education && (
                <p className="error-text">{errors?.education}</p>
              )}

              <label>Occupation</label>
              <input
                className={`form-control col-md-12 ${errors?.occupation ? "is-invalid" : ""
                  }`}
                name="occupation"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                value={formValues?.occupation}
                error={errors?.occupation ? true : false}
              />
              {errors?.occupation && (
                <p className="error-text">{errors?.occupation}</p>
              )}

              <label>Whom are you bait with?</label>
              <input
                className={`form-control col-md-12 ${errors?.baitStatus ? "is-invalid" : ""
                  }`}
                name="baitStatus"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                value={formValues?.baitStatus}
                error={errors?.baitStatus ? true : false}
              />
              {errors?.baitStatus && (
                <p className="error-text">{errors?.baitStatus}</p>
              )}

              <label>How long have you been bait?</label>
              <input
                className={`form-control col-md-12 ${errors?.baitDuration ? "is-invalid" : ""
                  }`}
                name="baitDuration"
                onFocus={focusHandler}
                onBlur={blurHandler}
                onChange={changeHandler}
                value={formValues?.baitDuration}
                error={errors?.baitDuration ? true : false}
              />
              {errors?.baitDuration && (
                <p className="error-text">{errors?.baitDuration}</p>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button variant="outlined" onClick={handleUpdClose}>
            CLOSE
          </Button>
          <Button variant="contained" autoFocus onClick={updateForm}>
            {loadingBtn ? (
              <PulseLoader color="#fff"
                style={{ display: "flex", justifyContent: "center" }}
              />
            ) : (
              "UPDATE"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default FloorPlan;
